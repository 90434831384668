import { useState, useEffect, ReactElement } from 'react'

import { Collapse, CssBaseline, Grid, Typography } from '@mui/material'
import { PlanteFilterUpdate } from '../data/models'
import useWindowSize from '../react/useWindowSize'
import BackgroundCard from '../components/frontpage/BackgroundCard';
import StartCard from '../components/frontpage/StartCard';
import HowToCard from '../components/frontpage/HowToCard'

export interface WelcomePageProps {
  onNext: (updates: PlanteFilterUpdate) => void
}

/*
function StartButton() {
  return (
  <div style={{ marginTop: -300 }}>              
    <Button sx={{ borderRadius: 4, width: 180, padding: 2 }} size="large" variant="contained" onClick={() => dispatch(nextPage())}>
      Start
    </Button>              
  </div>
  )
}
*/

function WelcomePageMd(props: WelcomePageProps): ReactElement {
  const windowSize = useWindowSize()
  //const [height, setHeight] = useState(0)

  const [open, setOpen] = useState(true)

  useEffect(() => {
    setTimeout(() => setOpen(true), 250)
  }, [setOpen])

  if (!windowSize || !windowSize.height || !windowSize.width) {
    return <></>
  }
  
  const rootStyle = {
    color: '#f8f8f8',
    minHeight: '125vh',
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/front_tall.jpg'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }
  const titleContainerStyle = {
    color: '#f8f8f8',
    height: windowSize.height - 450
  }

  return (
    <>
      <div style={rootStyle}>
        <CssBaseline />
        <div style={titleContainerStyle}>
          <Collapse in={open} timeout={500}>
            <div style={{ paddingLeft: 40, paddingTop: 80 }}>
              <Typography variant="h2">Find planten</Typography>
              <Typography sx={{ pl: 8, marginTop: -1, color: '#ccc' }} variant="h3">
                til dit biodiversitetsprojekt
              </Typography>
            </div>
          </Collapse>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <StartCard margin={2} logoHeight={50} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <HowToCard margin={2} />
          </Grid>          
          <Grid item xs={12} sm={12} md={6}>
          <BackgroundCard margin={2}/>
          </Grid>          
        </Grid>
      </div>
      
    </>
  )
}

export default WelcomePageMd
