import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Coordinate } from 'ol/coordinate'
import { Feature, FeatureCollection, GeomPoint } from '../ol/OlFeatureCollection'
import OlWebGlPointsLayer from '../ol/OlWebGlPointsLayer'
import OlStaticSource from '../ol/OlSource'
import OlLayers from '../ol/OlLayers'
import { OlTileLayer } from '../ol/OlLayers'
import OlView from '../ol/OlView'
import OpenLayersMap from '../ol/OpenLayersMap'
import useWindowSize from '../react/useWindowSize'
import { content } from '../texts/texts'
import { useDispatch } from 'react-redux'
import { fetchJordbundsinfo } from '../redux/app/appActions'

const mapView = { lat: 56, lon: 10.5, zoom: 7 }

const webGlStyle = {
  symbol: {
    symbolType: 'circle',
    size: ['interpolate', ['linear'], ['zoom'], 5, 6, 10, 10, 17, 18],
    color: 'rgb(255,96,96)',
    opacity: 1,
  },
}
const emptyFeatureCollection = (): FeatureCollection => ({ type: 'FeatureCollection', features: [] })
const createFeatureCollection = (features: Feature[]): FeatureCollection => ({ type: 'FeatureCollection', features })

const featureFromPoint = (point: number[]): Feature => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: point,
  },
  properties: {},
})

const featuresFromPoint = (point: number[] | null): Feature[] => {
  if (!point) {
    return []
  }
  return [featureFromPoint(point)]
}


export interface SelectAreaPageProps {
}


function SelectAreaPage(props: SelectAreaPageProps) {
  const dispatch = useDispatch()
  const size = useWindowSize()
  const [selectedPoint, setSelectedPoint] = useState<FeatureCollection>(emptyFeatureCollection())
  //console.log(size)
  if (!size.height || !size.width) {
    return null
  }
  let mapHeight = size.height - 340
  if (size.width < 1000) {
    mapHeight = 400
  }
  console.log({size, mapHeight})
  return (
    <>
      <Box sx={{ marginBottom: 1 }}>
        <Typography variant="body2">{content.selectAreaPage.body}</Typography>
      </Box>
        <OpenLayersMap id="select-area-map" left={0} right={0} height={mapHeight}>
          <OlView lat={mapView.lat} lon={mapView.lon} zoom={mapView.zoom} />
          <OlLayers>
            <OlTileLayer
              onClick={(coord: Coordinate) => {
                  const newPoint = createFeatureCollection(featuresFromPoint([coord[0], coord[1]]))
                  const geomPoint : GeomPoint = { lon: newPoint.features[0].geometry.coordinates[0], lat: newPoint.features[0].geometry.coordinates[1]}
                  setSelectedPoint(newPoint)
                  dispatch(fetchJordbundsinfo(geomPoint))
                  //onChange({ lon: newPoint.features[0].geometry.coordinates[0], lat: newPoint.features[0].geometry.coordinates[1]})
              }}
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <OlWebGlPointsLayer title="Punkt" visible={true} style={webGlStyle}>
              <OlStaticSource
                sourceProjection="EPSG:4326"
                targetProjection="EPSG:3857"
                featureCollection={selectedPoint}
              />
            </OlWebGlPointsLayer>
          </OlLayers>
        </OpenLayersMap>    
        <Typography sx={{ fontStyle: 'italic'}} variant="body2">{content.selectAreaPage.disclaimer}</Typography>
    </>
  )
}

export default SelectAreaPage
