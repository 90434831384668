import { Dispatch, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  AppBar,
  CircularProgress,
  IconButton,
  Toolbar,
  CssBaseline,
  ThemeProvider,
  ButtonGroup,
  Typography,
} from "@mui/material";

//import HomeIcon from "@mui/icons-material/Home";
import PaletteIcon from "@mui/icons-material/Palette";
import { pages } from "./data/enums";
import QuestionLayout from "./layout/QuestionLayout";
import PlanteListeResultatPage from "./pages/PlanteListeResultatPage";
import SelectAreaPage from "./pages/SelectAreaPage";
import SelectFugtighedPage from "./pages/SelectFugtighedPage";
import SelectJordbundPage from "./pages/SelectJordbundPage";
import SelectPhPage from "./pages/SelectPhPage";
import WelcomePage from "./pages/WelcomePage";
import {
  initApp,
  nextPage,
  restart,
  setViewMode,
} from "./redux/app/appActions";
import { MyRootStore } from "./redux/reducers";
import appTheme, { theme2, theme3, theme4, theme5, theme6 } from "./appTheme";
import SelectLysforholdPage from "./pages/SelectLysforholdPage";
import SelectNaeringsforholdPage from "./pages/SelectNaeringsforholdPage";
import ViewPlantePage from "./pages/ViewPlantePage";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import useWindowSize from "./react/useWindowSize";
import WelcomePageMd from "./pages/WelcomePageMd";
import WelcomePageSm from "./pages/WelcomePageSm";

const selectedStyle = {
  backgroundColor: "primary.main",  
  color: 'white',
  "&:hover": {
    color: "white",
    backgroundColor: "primary.main",
  },
};

const renderCurrentPage = (currentPage: string, windowSize: { width: number, height: number}, dispatch: Dispatch<any>) => {
  if (currentPage === pages.welcome.value) {
    if (windowSize.width > 1200) {
      return <WelcomePage onNext={() => dispatch(nextPage())} />;
    } else if (windowSize.width > 1000) {
      return <WelcomePageMd onNext={() => dispatch(nextPage())} />;
    } else {
      return <WelcomePageSm onNext={() => dispatch(nextPage())} />;
    }
  } else if (currentPage === pages.selectArea.value) {
    return (
      <QuestionLayout onNext={() => dispatch(nextPage())}>
        <SelectAreaPage />
      </QuestionLayout>
    );
  } else if (currentPage === pages.selectJordbund.value) {
    return (
      <QuestionLayout onNext={() => dispatch(nextPage())}>
        <SelectJordbundPage />
      </QuestionLayout>
    );
  } else if (currentPage === pages.selectPh.value) {
    return (
      <QuestionLayout onNext={() => dispatch(nextPage())}>
        <SelectPhPage />
      </QuestionLayout>
    );
  } else if (currentPage === pages.selectFugtighed.value) {
    return (
      <QuestionLayout onNext={() => dispatch(nextPage())}>
        <SelectFugtighedPage />
      </QuestionLayout>
    );
  } else if (currentPage === pages.selectLysforhold.value) {
    return (
      <QuestionLayout onNext={() => dispatch(nextPage())}>
        <SelectLysforholdPage />
      </QuestionLayout>
    );
  } else if (currentPage === pages.selectNaeringsforhold.value) {
    return (
      <QuestionLayout onNext={() => dispatch(nextPage())}>
        <SelectNaeringsforholdPage />
      </QuestionLayout>
    );
  } else if (currentPage === pages.showResult.value) {
    return <PlanteListeResultatPage />;
  } else {
    dispatch(restart());
    return <CircularProgress />;
  }
};

function App() {
  const dispatch = useDispatch();
  const mountedRef = useRef(false);

  const toggleTheme = false

  const { viewMode, selectedPlante, currentPage, loading } = useSelector(
    (state: MyRootStore) => state.app
  );

  const showAppBar = true //currentPage.value !== pages.welcome.value
  const [themeIdx, setThemeIdx] = useState(1);
  const themes = [appTheme, theme2, theme3, theme4, theme5, theme6];
  const windowSize = useWindowSize()

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      dispatch(initApp());
    }
  }, [dispatch, mountedRef]);

  if (!windowSize || !windowSize.width || !windowSize.height) {
    return null;
  }



  return (
    <ThemeProvider theme={themes[themeIdx]}>
      <CssBaseline enableColorScheme />     

      <Box
        sx={{
          cursor: loading ? "wait" : "default",
          width: "100%",
          height: "100vh",
        }}
      >
        { showAppBar &&
        <AppBar color="secondary" position="fixed" elevation={2}>
          <Toolbar>     
            <img style={{ cursor: 'pointer' }} onClick={() => dispatch(restart())} height="48" src='images/logo.png' alt='findplanten.dk (logo)'></img>  
            <Typography component='span' sx={{ flexGrow: 1 }} />         
            { currentPage.value === pages.showResult.value &&
            <ButtonGroup>
              <IconButton
                sx={viewMode === "list" ? selectedStyle : undefined}
                color={viewMode === "list" ? "primary" : "inherit"}
                onClick={() => {
                  dispatch(setViewMode("list"));
                }}
              >
                <FormatListBulletedIcon />
              </IconButton>
              <IconButton
                sx={
                  viewMode === "tile" ? selectedStyle : undefined
                }
                color={viewMode === "tile" ? "primary" : "inherit"}
                onClick={() => {
                  dispatch(setViewMode("tile"));
                }}
              >
                <GridViewIcon />
              </IconButton>
            </ButtonGroup>}
            { toggleTheme && <IconButton
              color="inherit"
              onClick={() => {
                let i = (themeIdx + 1) % themes.length;
                setThemeIdx(i);
              }}
            >
              <PaletteIcon />
            </IconButton>}
          </Toolbar>
        </AppBar>}

        {selectedPlante ? (
          <ViewPlantePage videnskabeligtNavn={selectedPlante.videnskabeligtNavn} planteId={selectedPlante.id} />
        ) : (
          windowSize && renderCurrentPage(currentPage.value, { width: windowSize.width, height: windowSize.height }, dispatch)
        )}
      </Box>
    </ThemeProvider>
  );
}

export default App;
