import { PageData } from "../../data/enums"
import { GeomPoint } from "../../ol/OlFeatureCollection"
import { ReduxAction } from "../actionInterface"
import { ViewModeEnum } from "./appReducer"

export const INIT_APP = '[app] init'

export const SET_CURRENT_PAGE = '[app] set current page'
export const RESTART = '[app] restart'

export const TO_NEXT_PAGE = '[app] to next page'
export const SHOW_RESULT = '[app] show result'

export const SET_LOADING = "[ui] set loading"

export const SET_VIEWMODE = '[app] set view'

export const SELECT_PLANTE = "[app] select plante"
export const DESELECT_PLANTE = "[app] deselect plante"
export const SET_SELECTED_PLANTE = "[app] set selected plante"

export const FETCH_JORDBUNDSINFO = "[app] fetch jordbundsinfo"

export interface ToNextPageAction extends ReduxAction {   
}

export interface SetLoadingAction extends ReduxAction {   
    payload: boolean
}

export interface SetCurrentPageAction extends ReduxAction {
    payload: {
        page: PageData;
    }
}

export interface SetViewModeAction extends ReduxAction {
    payload: {
        viewMode: ViewModeEnum
    }
}

export interface SelectPlanteAction extends ReduxAction {
    payload: {
        id: string;
        videnskabeligtNavn: string;
    }
}

export interface SetSelectedPlanteAction extends ReduxAction {
    payload: {
        id: string | null;
        videnskabeligtNavn?: string
    }
}

export interface FetchJordbundsinfoAction extends ReduxAction {
    payload: {
        point: GeomPoint;
    }
}

export const initApp = () : ReduxAction => ({
    type: INIT_APP
})

export const restart = () : ReduxAction => ({
    type: RESTART
})

export const nextPage = () : ToNextPageAction => ({
    type: TO_NEXT_PAGE
})

export const showResult = () : ReduxAction => ({
    type: SHOW_RESULT
})

export const setViewMode = (viewMode: ViewModeEnum) : SetViewModeAction => ({
    type: SET_VIEWMODE,
    payload: {
        viewMode
    }
})

export const setCurrentPage = (page : PageData) : SetCurrentPageAction => ({
    type: SET_CURRENT_PAGE,
    payload: { page }
})


export const selectPlante = (id : string, videnskabeligtNavn: string) : SelectPlanteAction => ({
    type: SELECT_PLANTE,
    payload: { id, videnskabeligtNavn }
})
export const deselectPlante = () : ReduxAction => ({
    type: DESELECT_PLANTE
})

export const setSelectedPlante = (id : string | null, videnskabeligtNavn?: string) : SetSelectedPlanteAction => ({
    type: SET_SELECTED_PLANTE,
    payload: { id, videnskabeligtNavn }
})

export const fetchJordbundsinfo = (point : GeomPoint) : FetchJordbundsinfoAction => ({
    type: FETCH_JORDBUNDSINFO,
    payload: { point }
})

export const loading = () : SetLoadingAction => ({
    type: SET_LOADING,
    payload: true
})


export const stopLoading = () : SetLoadingAction => ({
    type: SET_LOADING,
    payload: false
})