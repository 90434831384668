import { useState, useEffect, ReactElement } from "react";
import { useDispatch } from "react-redux";

import { Button, Collapse, CssBaseline, Typography } from "@mui/material";
import { PlanteFilterUpdate } from "../data/models";
import useWindowSize from "../react/useWindowSize";
import { nextPage } from "../redux/app/appActions";
import BackgroundCard from "../components/frontpage/BackgroundCard";
import StartCard from "../components/frontpage/StartCard";
import HowToCard from "../components/frontpage/HowToCard";

export interface WelcomePageProps {
  onNext: (updates: PlanteFilterUpdate) => void;
}

function WelcomePage(props: WelcomePageProps): ReactElement {
  const showButton = false;
  const windowSize = useWindowSize();
  //const [height, setHeight] = useState(0)
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => setOpen(true), 250);
  }, [setOpen]);

  if (!windowSize || !windowSize.height || !windowSize.width) {
    return <></>;
  }

  const rootStyle = {
    color: "#f8f8f8",
    minHeight: "125vh",
    backgroundImage: `url(${
      process.env.PUBLIC_URL + "/images/front_tall.jpg"
    })`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const titleContainerStyle = {
    color: "#f8f8f8",
    minHeight: "70vh",
  };

  return (
    <>
      <div style={rootStyle}>
        <CssBaseline />
        <div style={titleContainerStyle}>
          <Collapse in={open} timeout={500}>
            <div style={{ paddingLeft: 44, paddingTop: 80 }}>
              <Typography variant="h2">Find planten</Typography>
              <Typography
                sx={{ pl: 8, marginTop: -1, color: "#ccc" }}
                variant="h3"
              >
                til dit biodiversitetsprojekt
              </Typography>
            </div>
          </Collapse>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 48,
          }}
        >
          {showButton && (
            <div style={{ marginTop: -300 }}>
              <Button
                sx={{ borderRadius: 4, width: 180, padding: 2 }}
                size="large"
                variant="contained"
                onClick={() => dispatch(nextPage())}
              >
                Start
              </Button>
            </div>
          )}
          <StartCard fixedHeight logoHeight={50}/>
          <HowToCard fixedHeight />
          <BackgroundCard fixedHeight />
        </div>
      </div>
    </>
  );
}

export default WelcomePage;
