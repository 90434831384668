import produce from 'immer'
import { WritableDraft } from 'immer/dist/internal'
import { apply, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  ArtsgruppeEnumType,
  FughtighedEnumType,
  JordbundEnumType,
  LivsvarighedEnumType,
  LysforholdEnumType,
  MonthEnumType,
  NaeringsforholdEnumType,
  PlanteFilter,
  SurhedsgradEnumType,
  UdbredelseEnumType,
} from '../../data/models'
import { GeomPoint } from '../../ol/OlFeatureCollection'
import planteApi, { PlanteInfoResponse } from '../../service/planteApi'
import { ReduxAction } from '../actionInterface'
import { MyRootStore } from '../reducers'

import {
  INIT_FILTER,
  SetNullableFilterAction,
  SetLivsvarighedAction,
  SetPointAction,
  SET_BLOMSTRINGSTIDSPUNKT,
  SET_FILTER,
  SET_FUGTIGHED,
  SET_JORDBUND,
  SET_LIVSVARIGHED,
  SET_LYSFORHOLD,
  SET_NAERINGSFORHOLD,
  SET_POINT,
  SET_SURHEDSGRAD,
  SetTypedFilterAction,
  SET_BLOMSTERFARVE,
  SET_PLANTEHOJDE,
  SET_USEPLANTEHOJDE,
  SET_ARTER,
  SET_UDBREDELSE,
} from './filterActions'
import { setFilter, setPlanteListe, SetFilterAction } from './filterActions'
import { SetFilterStringAction } from './filterActions'

export interface ApiResponse<T> {
  data: T
}

function* processInitFilter(action: ReduxAction) {
  try {
    yield put(
      setFilter({
        udbredelse: [],
        point: null,
        jordbund: null,
        fugtighed: null,
        livsvarighed: [],
        blomsterfarve: [],
        blomstringstidspunkt: null,
        lysforhold: 'null',
        naeringsforhold: 'null',
        ph: null,
        artsgrupper: [],
        plantehojde: [0,100],
        usePlantehojde: false,
      })
    )
  } catch (error: any) {
    console.log(error)
  }
}

function* processSetFilter(action: SetFilterAction) {
  try {
    const response: PlanteInfoResponse = yield apply(planteApi, planteApi.getByFilter, [action.payload.filter])
    yield put(setPlanteListe(response.data))
  } catch (error) {}
}

function* processSetPoint(action: SetPointAction) {
  try {
    const point: GeomPoint = action.payload.point
    console.log('point', action)
    const filter: PlanteFilter = yield select((state: MyRootStore) => state.filter.planteFilter)
    const nextFilter = produce<PlanteFilter>(filter, (draft: WritableDraft<PlanteFilter>) => {
      draft.point = point
    })
    yield put(setFilter(nextFilter))
  } catch (error) {
    console.log(error)
  }
}
function* processSetUdbredelse(action: SetTypedFilterAction<UdbredelseEnumType[]>) {
  try {
    const udbredelse: UdbredelseEnumType[] = action.payload
    console.log('udbredelse', action)
    const filter: PlanteFilter = yield select((state: MyRootStore) => state.filter.planteFilter)
    const nextFilter = produce<PlanteFilter>(filter, (draft: WritableDraft<PlanteFilter>) => {
      draft.udbredelse = udbredelse
    })
    yield put(setFilter(nextFilter))
  } catch (error) {
    console.log(error)
  }
}

function* processSetJordbund(action: SetFilterStringAction) {
  try {
    const jordbund: JordbundEnumType = action.payload as JordbundEnumType
    console.log('jordbund', action)
    const filter: PlanteFilter = yield select((state: MyRootStore) => state.filter.planteFilter)
    const nextFilter = produce<PlanteFilter>(filter, (draft: WritableDraft<PlanteFilter>) => {
      draft.jordbund = jordbund
    })
    yield put(setFilter(nextFilter))
  } catch (error) {
    console.log(error)
  }
}

function* processSetSurhedsgrad(action: SetFilterStringAction) {
  try {
    const surhedsgrad: SurhedsgradEnumType = action.payload as SurhedsgradEnumType
    console.log('surhedsgrad', action)

    const filter: PlanteFilter = yield select((state: MyRootStore) => state.filter.planteFilter)
    const nextFilter = produce<PlanteFilter>(filter, (draft: WritableDraft<PlanteFilter>) => {
      draft.ph = surhedsgrad
    })
    yield put(setFilter(nextFilter))
  } catch (error) {
    console.log(error)
  }
}

function* processSetFugtighed(action: SetFilterStringAction) {
  try {
    const fugtighed: FughtighedEnumType = action.payload as FughtighedEnumType
    console.log('fugtighed', action)

    const filter: PlanteFilter = yield select((state: MyRootStore) => state.filter.planteFilter)
    const nextFilter = produce<PlanteFilter>(filter, (draft: WritableDraft<PlanteFilter>) => {
      draft.fugtighed = fugtighed
    })
    yield put(setFilter(nextFilter))
  } catch (error) {
    console.log(error)
  }
}

function* processSetLysforhold(action: SetFilterStringAction) {
  try {
    const lysforhold: LysforholdEnumType = action.payload as LysforholdEnumType
    console.log('lysforhold', action)

    const filter: PlanteFilter = yield select((state: MyRootStore) => state.filter.planteFilter)
    const nextFilter = produce<PlanteFilter>(filter, (draft: WritableDraft<PlanteFilter>) => {
      draft.lysforhold = lysforhold
    })
    yield put(setFilter(nextFilter))
  } catch (error) {
    console.log(error)
  }
}

function* processSetNaeringsforhold(action: SetFilterStringAction) {
  try {
    const naeringsforhold: NaeringsforholdEnumType = action.payload as NaeringsforholdEnumType
    console.log('naeringsforhold', action)

    const filter: PlanteFilter = yield select((state: MyRootStore) => state.filter.planteFilter)
    const nextFilter = produce<PlanteFilter>(filter, (draft: WritableDraft<PlanteFilter>) => {
      draft.naeringsforhold = naeringsforhold
    })
    yield put(setFilter(nextFilter))
  } catch (error) {
    console.log(error)
  }
}


function* processSetLivsvarighed(action: SetLivsvarighedAction) {
  try {
    const livsvarighed: LivsvarighedEnumType[] = action.payload
    console.log('livsvarighed', action)

    const filter: PlanteFilter = yield select((state: MyRootStore) => state.filter.planteFilter)
    const nextFilter = produce<PlanteFilter>(filter, (draft: WritableDraft<PlanteFilter>) => {
      draft.livsvarighed = livsvarighed
    })
    yield put(setFilter(nextFilter))
  } catch (error) {
    console.log(error)
  }
}

function* processSetBlomstringstidspunkt(action: SetNullableFilterAction<MonthEnumType>) {
  try {
    const blomstringstidspunkt: MonthEnumType | null = action.payload
    console.log('blomstringstidspunkt', action)

    const filter: PlanteFilter = yield select((state: MyRootStore) => state.filter.planteFilter)
    const nextFilter = produce<PlanteFilter>(filter, (draft: WritableDraft<PlanteFilter>) => {
      draft.blomstringstidspunkt = blomstringstidspunkt
    })
    yield put(setFilter(nextFilter))
  } catch (error) {
    console.log(error)
  }
}

function* processSetBlomsterfarve(action: SetTypedFilterAction<string[]>) {
  try {
    const blomsterfarve: string[] = action.payload
    console.log('blomsterfarve', action)

    const filter: PlanteFilter = yield select((state: MyRootStore) => state.filter.planteFilter)
    const nextFilter = produce<PlanteFilter>(filter, (draft: WritableDraft<PlanteFilter>) => {
      draft.blomsterfarve = blomsterfarve
    })
    yield put(setFilter(nextFilter))
  } catch (error) {
    console.log(error)
  }
}

function* processSetPlantehojde(action: SetTypedFilterAction<number[]>) {
  try {
    const plantehojde: number[] = action.payload
    console.log('plantehojde', action)

    const filter: PlanteFilter = yield select((state: MyRootStore) => state.filter.planteFilter)
    const nextFilter = produce<PlanteFilter>(filter, (draft: WritableDraft<PlanteFilter>) => {
      draft.plantehojde = plantehojde
    })
    yield put(setFilter(nextFilter))
  } catch (error) {
    console.log(error)
  }
}

function* processSetUsePlantehojde(action: SetTypedFilterAction<boolean>) {
  try {
    const useplantehojde: boolean = action.payload
    console.log('plantehojde', action)

    const filter: PlanteFilter = yield select((state: MyRootStore) => state.filter.planteFilter)
    const nextFilter = produce<PlanteFilter>(filter, (draft: WritableDraft<PlanteFilter>) => {
      draft.usePlantehojde = useplantehojde
    })
    yield put(setFilter(nextFilter))
  } catch (error) {
    console.log(error)
  }
}

function* processSetArter(action: SetTypedFilterAction<ArtsgruppeEnumType[]>) {
  try {
    const arter: ArtsgruppeEnumType[] = action.payload
    console.log('arter', action)

    const filter: PlanteFilter = yield select((state: MyRootStore) => state.filter.planteFilter)
    const nextFilter = produce<PlanteFilter>(filter, (draft: WritableDraft<PlanteFilter>) => {
      draft.artsgrupper = arter
    })
    yield put(setFilter(nextFilter))
  } catch (error) {
    console.log(error)
  }
}

export default function* watcher() {
  yield takeLatest(INIT_FILTER, processInitFilter)
  yield takeLatest(SET_FILTER, processSetFilter)
  yield takeEvery(SET_POINT, processSetPoint)
  yield takeEvery(SET_UDBREDELSE, processSetUdbredelse)
  yield takeEvery(SET_JORDBUND, processSetJordbund)
  yield takeEvery(SET_SURHEDSGRAD, processSetSurhedsgrad)
  yield takeEvery(SET_FUGTIGHED, processSetFugtighed)
  yield takeEvery(SET_LYSFORHOLD, processSetLysforhold)
  yield takeEvery(SET_NAERINGSFORHOLD, processSetNaeringsforhold)
  yield takeEvery(SET_LIVSVARIGHED, processSetLivsvarighed)
  yield takeEvery(SET_BLOMSTRINGSTIDSPUNKT, processSetBlomstringstidspunkt)
  yield takeEvery(SET_BLOMSTERFARVE, processSetBlomsterfarve)
  yield takeEvery(SET_PLANTEHOJDE, processSetPlantehojde)
  yield takeEvery(SET_USEPLANTEHOJDE, processSetUsePlantehojde)
  yield takeEvery(SET_ARTER, processSetArter)
}
