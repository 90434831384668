import {  Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import {
  artsgruppeOptions,
  blomsterfarveOptions,
  fugtighedOptions,
  jordbundsOptions,
  livsvarighedOptions,
  lysforholdOptions,
  months,
  naerigsforholdOptions,
  phOptions,
} from '../data/enums'
import {
  ArtsgruppeEnumType,
  FughtighedEnumType,
  IdOption,
  LivsvarighedEnumType,
  LysforholdEnumType,
  MonthEnumType,
  NaeringsforholdEnumType,
  Option,
  PlanteFilter,
} from '../data/models'
import {
  setArter,
  setBlomsterfarve,
  setBlomstringstidspunkt,
  setFugtighed,
  setJordbund,
  setLivsvarighed,
  setLysforhold,
  setNaeringsforhold,
  setPlantehojde,
  setSurhedsgrad,
  setUsePlantehojde,
} from '../redux/filter/filterActions'
import FilterPanel from './Sidebar/FilterPanel'
import FilterPanelInterval from './Sidebar/FilterPanelInterval'
import FilterPanelMultiSelect from './Sidebar/FilterPanelMultiSelect'
import FilterPanelSelect from './Sidebar/FilterPanelSelect'

export interface SidebarFilterProps {
  filter: PlanteFilter
}

const idOptionToOption = (o: IdOption | undefined) => {
  if (!o) {
    return null
  }
  return {
    value: o.value,
    label: o.label,
  }
}

const SidebarFilter = ({ filter }: SidebarFilterProps) => {
  const dispatch = useDispatch()
  const selectedJordbund = jordbundsOptions.find((o: IdOption) => o.value === filter.jordbund)
  const selectedPh = phOptions.find((o: IdOption) => o.value === filter.ph)
  const selectedFugtighed = fugtighedOptions.find((o: Option<FughtighedEnumType>) => o.value === filter.fugtighed)
  const selectedLysforhold = lysforholdOptions.find((o: Option<LysforholdEnumType>) => o.value === filter.lysforhold)
  const selectedNaeringsforhold = naerigsforholdOptions.find(
    (o: Option<NaeringsforholdEnumType>) => o.value === filter.naeringsforhold
  )

  const selectedLivsvarighed = livsvarighedOptions.filter((o: Option<LivsvarighedEnumType>) =>
    filter.livsvarighed.find((l: LivsvarighedEnumType) => l === o.value)
  )
  const selectedBlomsterfarver : Option<string>[] = blomsterfarveOptions.filter((o: Option<string>) =>
    filter.blomsterfarve.find((l: string) => l === o.value)
  )

  const selectedBlomstringstidspunkt =
    months.find((o: Option<MonthEnumType>) => o.value === filter.blomstringstidspunkt) || null

  return (
    <Box sx={{ width: '100%' }}>
      <FilterPanel
        summary={selectedJordbund}
        title="Jordbund"
        onChange={(newValue: any) => dispatch(setJordbund(newValue.value))}
        value={idOptionToOption(selectedJordbund)}
        options={jordbundsOptions.map((o: IdOption) => ({
          value: o.value,
          label: o.label,
        }))}
      />
      <FilterPanel
        title="Surhedsgrad"
        summary={selectedPh}
        onChange={(newValue: Option<string>) => dispatch(setSurhedsgrad(newValue.value))}
        value={idOptionToOption(selectedPh)}
        options={phOptions.map((o: Option<string>) => ({
          value: o.value as string,
          label: o.label,
        }))}
      />
      <FilterPanel
        title="Fugtighed"
        summary={selectedFugtighed}
        onChange={(newValue: Option<string>) => dispatch(setFugtighed(newValue.value))}
        value={selectedFugtighed}
        options={fugtighedOptions.map((o: Option<string>) => ({
          value: o.value,
          label: o.label,
        }))}
      />
      <FilterPanel
        title="Lysforhold"
        summary={selectedLysforhold}
        nullOption={lysforholdOptions[0]}
        onChange={(newValue: Option<LysforholdEnumType>) => dispatch(setLysforhold(newValue.value))}
        value={selectedLysforhold || lysforholdOptions[0]}
        options={lysforholdOptions.map((o: Option<LysforholdEnumType>) => ({
          value: o.value,
          label: o.label,
        }))}
      />
      <FilterPanel
        title="Næringsforhold"
        summary={selectedNaeringsforhold}
        nullOption={naerigsforholdOptions[0]}
        onChange={(newValue: any) => dispatch(setNaeringsforhold(newValue.value))}
        value={selectedNaeringsforhold || naerigsforholdOptions[0]}
        options={naerigsforholdOptions.map((o: Option<string>) => ({
          value: o.value,
          label: o.label,
        }))}
      />     
      <FilterPanelMultiSelect
        title="Livsvarighed"
        onChange={(newValue: LivsvarighedEnumType[]) => dispatch(setLivsvarighed(newValue))}
        values={selectedLivsvarighed}
        options={livsvarighedOptions}
      />
      <FilterPanelSelect
        title="Blomstringstidspunkt"
        onChange={(newValue: MonthEnumType | null) => dispatch(setBlomstringstidspunkt(newValue))}
        value={selectedBlomstringstidspunkt}
        options={months}
      />
      <FilterPanelMultiSelect
        title="Blomsterfarve"
        onChange={(newValue: string[]) => dispatch(setBlomsterfarve(newValue))}
        values={selectedBlomsterfarver}
        options={blomsterfarveOptions}
      />
      <FilterPanelInterval
        title='Plantehøjde'
        use={filter.usePlantehojde}
        initialValue={50}
        min={5}
        max={4000}
        value={filter.plantehojde}
        onChangeUse={(use : boolean) => dispatch(setUsePlantehojde(use))}
        onChangeValue={(value : number[]) => dispatch(setPlantehojde(value))}
         />    
      <FilterPanelMultiSelect
        title='Faunagrupper'
        onChange={(newValue: ArtsgruppeEnumType[]) => dispatch(setArter(newValue))}         
        values={artsgruppeOptions.filter((o : Option<ArtsgruppeEnumType>) => filter.artsgrupper.find((a : ArtsgruppeEnumType) => a === o.value)) }
        options={artsgruppeOptions}
        />    
    </Box>
  )
}

export default SidebarFilter
