import { UdbredelseEnumType } from "../data/models";
import { GeomPoint } from "../ol/OlFeatureCollection"

export interface JordbundInfo {
  jordbund: number;
  ph: number;
  udbredelse: UdbredelseEnumType[];
}

class JordundApi {
  baseUrl: string | null

  constructor() {
    this.baseUrl = null
  }

  setBaseUrl(url : string) { 
    this.baseUrl = url
  }

  async getJordbundInfo(point : GeomPoint) : Promise<JordbundInfo> {
    const response = await fetch(`${this.baseUrl}/jordbund?lon=${point.lon}&lat=${point.lat}`)
    const data = await response.json()
    return data
  }
}

export default new JordundApi()
