import {
  Grid,
  Box,
  Button 
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EcosStepContainer from "../components/steps/EcosStepContainer";
import { PageData, questionPageValidators } from "../data/enums";
import { UdbredelseEnumType } from "../data/models";
import { setCurrentPage, showResult } from "../redux/app/appActions";
import { MyRootStore } from "../redux/reducers";

const QuestionLayout = (props: any) => {
  const dispatch = useDispatch();
  const area : UdbredelseEnumType[] = useSelector((state : MyRootStore) => state.filter.planteFilter.udbredelse)

  const questionPages = useSelector(
    (state: MyRootStore) => state.app.questionPages
  );
  const currentPage = useSelector(
    (state: MyRootStore) => state.app.currentPage
  );
  const loading = useSelector(
    (state: MyRootStore) => state.app.loading
  );

  const filter = useSelector(
    (state: MyRootStore) => state.filter.planteFilter
  );
  const valid = questionPageValidators[currentPage.value](filter)
  return (     
      <Box sx={{ padding: 4, margin: 'auto', marginTop: 8, maxWidth: 1440, width: '90%'}}>
        <Box sx={{ marginBottom: 3}}>
          <EcosStepContainer
            onSelect={(p: PageData) => dispatch(setCurrentPage(p))}
            currentPage={currentPage}
            pages={questionPages}
          />
        </Box>        
        <Box sx={{ marginX: 2, minHeight: 'calc(100vh - 248px)' }}>
          {props.children}        
        </Box>
        <Box sx={{  margin: 0, marginTop: 1}}>
          <Grid container>
            <Grid item xs={12} sm={9} lg={10}>
          <Button disabled={!valid || loading} fullWidth color='primary' variant="contained" onClick={() => props.onNext({})}>
            Videre
          </Button>
          </Grid>
          <Grid item xs={12} sm={3} lg={2}>
          <Button disabled={!area || area.length === 0} fullWidth color='primary' variant="contained" onClick={() => dispatch(showResult())}>
            Vis resultat
          </Button>
          </Grid>
          </Grid>
        </Box>
      </Box>
  );
};

export default QuestionLayout;
