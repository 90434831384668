import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Slider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FilterTitle } from "./FilterPanel";

export interface FilterPanelIntervalProps {
  title: string;
  use: boolean;
  initialValue: number;
  min: number;
  max: number;
  value: number[];
  onChangeValue: (value: number[]) => void;
  onChangeUse: (use: boolean) => void;
}

const scaleFunc = (value: number): number => {
  if (value <= 250) {
    return value;
  }
  const v = (value - 250) * 100;
  return v + 300;
};

const formatValue = (x: number): string => {
  if (x <= 250) return `${x}cm`;
  else return `${x / 100}m`;
};

function FilterPanelInterval(props: FilterPanelIntervalProps) {
  const { min, max, title, use, value, onChangeValue, onChangeUse } =
    props;
  const [expanded, setExpanded] = useState(false);
  const [internalValue, setInternalValue] = useState(value);
  const _summary =
    use && value.length >= 2
      ? `${formatValue(value[0])} - ${formatValue(value[1])}`
      : "";

  console.log("value", value);
  const scaledMin = min; // Math.floor(Math.sqrt(min))
  const scaledMax = 247 + max / 100; //Math.ceil(Math.sqrt(max))

  return (
    <Accordion
      expanded={expanded}
      onChange={(evt: any, expanded: boolean) => setExpanded(expanded)}
    >
      <AccordionSummary>
        <FilterTitle title={title} />
        <Typography
          sx={{
            paddingLeft: 1,
            paddingTop: "0.2em",
            fontSize: "0.8em",
            color: "rgba(0, 0, 0, 0.54)",
          }}
        >
          {_summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup>
          <FormControlLabel
            value={use}
            control={<Checkbox color="primary" />}
            label={"Anvend"}
            onChange={(evt: any) => onChangeUse(evt.target.checked)}
          />
        </FormGroup>
        <Slider
          color="primary"
          valueLabelDisplay={"auto"}
          valueLabelFormat={(x: number, idx: number) => formatValue(x)}
          disabled={!use}
          step={1}
          min={scaledMin}
          max={scaledMax}
          scale={scaleFunc}
          value={internalValue}
          onChange={(evt, x: number | number[]) =>
            setInternalValue(x as number[])
          }
          onChangeCommitted={(evt: any, value: number | number[]) =>
            onChangeValue((value as number[]).map(scaleFunc))
          }
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default FilterPanelInterval;
