import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import { SelectOptionPageProps } from './pageModel';
import { naerigsforholdOptions } from '../data/enums';
import { content } from '../texts/texts';
import { useDispatch, useSelector } from 'react-redux';
import { MyRootStore } from '../redux/reducers';
import { setNaeringsforhold } from '../redux/filter/filterActions';
import RadioGroupWithDescription from '../components/common/RadioGroupWithDescription';


function SelectNaeringsforholdPage(props: SelectOptionPageProps) : ReactElement {
  const dispatch = useDispatch()
  const {
    naeringsforhold
  } = useSelector(( state : MyRootStore) => state.filter.planteFilter)
  
  return (
    <>
      <Box sx={{ marginBottom: 2}}>
        <Typography variant="body2">
          {content.selectNaeringsforholdPage.body}
        </Typography>
      </Box>
      <Box>               
        <RadioGroupWithDescription ariaLabel='Vælg næringsforhold' options={naerigsforholdOptions} value={naeringsforhold} onChange={value => dispatch(setNaeringsforhold(value))} />
      </Box>
    </>
  )
}

export default SelectNaeringsforholdPage