import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { artsgruppeOptions } from '../data/enums'
import { ArtsgruppeEnumType, Option, PlanteInfo } from '../data/models'
//import { artsgruppeOptions, blomsterfarveOptions, livsvarighedOptions } from '../data/enums'
import { selectPlante } from '../redux/app/appActions'

import { MyRootStore } from '../redux/reducers'
import PagedDataList, { ListFieldFnCell, TextFieldCell } from './common/PagedDataList'

const findArtsgruppe = (value: any) : Option<ArtsgruppeEnumType> => 
  artsgruppeOptions.find(e => e.value === value) || artsgruppeOptions[0]

const PlanteListeListView = (props: any) => {
  const planteliste = useSelector((state: MyRootStore) => state.filter.planteliste)
  const dispatch = useDispatch()
  
  if (planteliste.length < 1) {
    return (<div>
      Ingen resultater. Opdater filtre
    </div>)
  }
  
  planteliste.sort((a: any, b: any) => a.danskNavn.localeCompare(b.danskNavn))
    
  return (
    <React.Fragment>
        <PagedDataList
          onClick={(item : PlanteInfo) => dispatch(selectPlante(item.id, item.videnskabeligtNavn))}
          items={planteliste || []}
          >
            <TextFieldCell source='danskNavn' title='Dansk navn' />
            <TextFieldCell source='videnskabeligtNavn' title='Videnskabeligt navn' />
            <ListFieldFnCell source='artsgrupper' title='Faunagrupper' mapfn={e => findArtsgruppe(e).label} />
        </PagedDataList>
    </React.Fragment>
  )
}

export default PlanteListeListView
