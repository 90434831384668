import { useEffect, useState } from "react";

import { Grid, Link, Paper, Typography, Button } from "@mui/material";
import { PlanteInfo } from "../data/models";
import planteApi, { FaunaInteraktionerResponse } from "../service/planteApi";
import { useDispatch, useSelector } from "react-redux";
import { MyRootStore } from "../redux/reducers";
import { deselectPlante } from "../redux/app/appActions";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import useWindowSize from "../react/useWindowSize";

const ViewPlantePage = ({
  planteId,
  videnskabeligtNavn,
}: {
  planteId: string;
  videnskabeligtNavn: string;
}) => {
  const dispatch = useDispatch();
  const [planteInfo, setPlanteInfo] = useState<PlanteInfo | null>(null);
  const [faunaInteraktioner, setFaunaInteraktioner] = useState<string | null>(
    null
  );
  const imageBaseUrl = useSelector(
    (state: MyRootStore) => state.app.imageBaseUrl
  );

  const size = useWindowSize()

  useEffect(() => {
    planteApi
      .getFaunaInteraktioner(videnskabeligtNavn)
      .then((p: FaunaInteraktionerResponse) => {
        setFaunaInteraktioner(p.description);
      });
    planteApi.getById(planteId).then((p: PlanteInfo | null) => {
      setPlanteInfo(p);
    });
  }, [planteId, videnskabeligtNavn, setPlanteInfo, setFaunaInteraktioner]);

  if (!planteInfo) {
    return <></>;
  }

  return (
    <Paper sx={{ marginTop: 12, marginLeft: 4, marginRight: 4, paddingLeft: 2, backgroundColor: "#f8f8f8" }}>
      <Button
        color="primary"
        variant="contained"
        sx={{ position: "absolute", right: 32 }}
        onClick={() => dispatch(deselectPlante())}
      >
        Luk
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">{planteInfo.danskNavn}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            {faunaInteraktioner && (
              <ReactMarkdown children={faunaInteraktioner as string} />
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            {planteInfo.arterDk ? (
              <Link href={planteInfo.arterDk}  target="_blank">Se på arter.dk</Link>
            ) : null}
          </Typography>
          <Typography variant="body1">
            {planteInfo.naturbasen ? (
              <Link href={planteInfo.naturbasen} target="_blank">Se på Naturbasen</Link>
            ) : null}
          </Typography>
          <Typography variant="body1">
            Plantehøjde: {planteInfo.plantehojdeMin} - {planteInfo.plantehojdeMax}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={12}>
          <img
            width={size.width && size.width < 900 ? size.width - 112 : undefined}            
            alt={planteInfo.imageAlt}
            src={`${imageBaseUrl}/medium/${planteInfo.imageUrl}`}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ViewPlantePage;
