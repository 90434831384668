import { PlanteFilter, PlanteInfo } from "../../data/models";
import { SetFilterAction, SetPlanteListeAction, SET_FILTER, SET_PLANTELISTE } from "./filterActions";



//const option = (label: string, value: string) => ({ label, value })


export interface FilterStore {
    planteFilter: PlanteFilter;
    planteliste: PlanteInfo[];
}  

const initialState : FilterStore = {
    planteFilter: {
        udbredelse: [],
        point: null,
        jordbund: null,
        fugtighed: null,
        livsvarighed: [],
        blomsterfarve: [],
        blomstringstidspunkt: null,
        lysforhold: 'null',
        naeringsforhold: 'null',
        ph: null,
        artsgrupper: [],
        plantehojde: [0,100],
        usePlantehojde: false
    },
    planteliste: [],
}

const filter = (state = initialState, action : any) => {
    switch (action.type) {
       case SET_FILTER:
            const setFilterAction : SetFilterAction = action
            return { ...state, planteFilter: setFilterAction.payload.filter }   
        case SET_PLANTELISTE:
            const setPlanteListeAction : SetPlanteListeAction = action
            return { ...state, planteliste: setPlanteListeAction.payload.planteliste}
        default:
            return state
    }
}

export default filter
