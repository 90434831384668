import { capitalizeFirstLetter } from "../util/utils"
import { strToMonth } from "./enums"
import { ArtsgruppeEnumType, MonthEnumType, MonthInterval, PlanteInfo, UdbredelseEnumType } from "./models"

const stringToArray = (str: string) : string[] | null => {
    try {
        return str ? ("" + str).split(',').map(e => e.trim()) : null
    } catch (error) {
        console.log('error', str, error)
        return [str]
    }
}


export const toBool = (str: string) => Boolean(str === '+' || str === 'X' || str === 'x')

export const toInt = (str: string) : number | null => {
    const num = parseInt(str)
    if (isNaN(num)) {
        return null
    }
    return num
}

export const toUdbredelse = (str: string) : UdbredelseEnumType[] => {
    if (str === 'hele DK') {
        return [ 'east', 'west' ]
    }
    if (str === 'Øst DK') {
        return [ 'east' ]
    }
    if (str === 'Vest DK') {
        return [ 'west']
    }
    return []
}

const toMonthInterval = (str: string) : MonthInterval | null => {
    if (!str) {
        return null
    }
    const months : any = str.split('-').map((s: string) => strToMonth(s.trim() as MonthEnumType)).filter(m => m !== null)
    if (months.length !== 2) {
        return null
    }
    return {
        start: months[0],
        end: months[1]
    }
}

const transform = (rawData: any) : PlanteInfo[] => rawData.map((row: any) : PlanteInfo =>  {
    const id = row.arterDk

    const jordbund = {
        grus: toBool(row.grus),
        sand: toBool(row.sand),
        ler: toBool(row.ler),
        stivler: toBool(row.stivler),
        muld: toBool(row.muld),
        morbund: toBool(row.morbund),
        humus: toBool(row.humus),
        torvebund: toBool(row.torvebund)
    }

    const surhedsgrad = {
        staerksur: toBool(row.staerksur),
        sur: toBool(row.sur),
        neutral: toBool(row.neutral),
        basisk: toBool(row.basisk)
    }

    const fugtighed = {
        vaad: toBool(row.vaad),
        fugtig: toBool(row.fugtig),
        toer: toBool(row.toer),        
    }

    const lysforhold = {
        skygge: toBool(row.skygge),
        solskygge: toBool(row.sol_skygge),
        lysaabent: toBool(row.lysaabent)
    }

    const naeringsforhold = {
        rigt: toBool(row.naeringsrigt),
        fattigt: toBool(row.naeringsfattigt)
    }

    const livsvarighed = {
        enaarig: toBool(row.enaarig),
        toaarig: toBool(row.toaarig),
        fleraarig: toBool(row.fleraarig)
    }

    const udbredelse : UdbredelseEnumType[] = toUdbredelse(row.udbredelse)
    
    const blomsterfarve = stringToArray(row.blomsterfarve)
    const blomstringstidspunkt = row.blomstringstidspunkt
    const humlebier = toBool(row["humlebier"])
    const enligeBier = toBool(row["enlige_bier"])
    const dagsommerfugle = toBool(row["dagsommerfugle"])
    const svirrefluer = toBool(row["svirrefluer"])
    const fugle = toBool(row.fugle)
    const andreArter = toBool(row["andre_arter"])


    const artsgrupper : ArtsgruppeEnumType[] = []
    if (humlebier) {
        artsgrupper.push('humlebier')
    }
    if (enligeBier) {
        artsgrupper.push('enligeBier')
    }
    if (dagsommerfugle) {
        artsgrupper.push('dagsommerfugle')
    }
    if (svirrefluer) {
        artsgrupper.push('svirrefluer')
    }
    if (fugle) {
        artsgrupper.push('fugle')
    }
    if (andreArter) {
        artsgrupper.push('andreArter')
    }

    const plantehojder = row.plantehojde && row.plantehojde.split('-')
    const plantehojdeMin : null | number = (plantehojder && plantehojder.length === 2) ? parseInt(plantehojder[0]) : null
    const plantehojdeMax : null | number = (plantehojder && plantehojder.length === 2) ? parseInt(plantehojder[1]) : null

    const blomstringInterval = toMonthInterval(blomstringstidspunkt)
    const scientificName = row["videnskabeligtNavn"]
    const imageName = row.imageName
    const vernacularName = capitalizeFirstLetter(row["danskNavn"])
    return {
        id,
        udbredelse,
        arterDk: row.arterDk,
        naturbasen: row.naturbasen,
        imageTitle: vernacularName,
        imageSubtitle: scientificName,
        imageUrl: imageName,
        imageAlt: vernacularName,
        danskNavn: vernacularName,
        videnskabeligtNavn: scientificName,
        fugtighed,
        jordbund,
        ph: surhedsgrad,
        naeringsforhold,
        lysforhold,
        livsvarighed,
        blomsterfarve,
        blomstringstidspunkt,
        blomstringInterval,
        artsgrupper,
        plantehojdeMin,
        plantehojdeMax
    }
})

export default transform