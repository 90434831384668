import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
  } from "@mui/material";
  import Select from "react-select";
  

export const FilterTitle = ({ title } : { title: string })  => {
    return ( 
      <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
    )
  }
  
  const FilterPanel = (props: any) => {  
    const { title, summary, nullOption, value, options, onChange } = props
    const _summary = summary !== null && summary !== nullOption ? summary?.label : ''
    return (
      <Accordion>
        <AccordionSummary>
          <FilterTitle title={title} />
          <Typography
                sx={{
                  paddingLeft: 1,
                  paddingTop: "0.2em",
                  fontSize: "0.8em",
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              >
                {_summary}
              </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Select
            onChange={(newValue: any) => onChange(newValue)}
            value={value}
            options={options}
          />
        </AccordionDetails>
      </Accordion>
    );
  };
  
  export default FilterPanel