
import { createTheme } from '@mui/material';
import { blueGrey, green } from '@mui/material/colors';
//import { grey, blueGrey, brown, deepPurple, green } from '@mui/material/colors';

export const juni15Color = '#8e8c13'

const auPurple = {
  "light": "#ba68c8",
  "main": "#655a9f",
  "dark": "#281c41"
}

const auGreen = {
  "main": "#425821",
  "light": "#8bad3f",
  "dark": "#233C00"
}

const defaultPurple = {
  "main": "#9c27b0",
  "light": "#ba68c8",
  "dark": "#7b1fa2",
  "contrastText": "#fff"
}
//green["800"]
//"light": green["700"],
//"dark": green["900"],

const darkGreen = {
  "main": "#233C00",
  "light": "#8bad3f",
  "dark": green["900"],
  "contrastText": "#fff"
}

const darkGreen2 = {
  "main": "#233C00",
  "light": "#425821",
  "dark": green["900"],
  "contrastText": "#fff"
}

const theme = createTheme({
  palette: {
    primary: auPurple,
    secondary: darkGreen2
  },
});



export const theme6 = createTheme({
  palette: {
    primary: defaultPurple,
    secondary: blueGrey
  },
});

export const theme2 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1380,
      xl: 1536,
    }}
    ,
  palette: {
    primary: defaultPurple,
    secondary: darkGreen
  }
})

export const theme3 = createTheme({
  palette: {
    primary: defaultPurple,
    secondary: auGreen
  }
})

export const theme4 = createTheme({
  palette: {
    primary: auPurple,
    secondary: auGreen
  }
})

export const theme5 = createTheme({
  palette: {
    primary: auPurple,
    secondary: darkGreen
  }
})



export default theme