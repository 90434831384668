import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { useMediaQuery } from '@mui/material';

export interface ImageItem {
  imageUrl: string;
  imageAlt: string;
  imageTitle: string;
  imageSubtitle: string;
}

export interface ResponsiveItemListProps<T extends ImageItem> {
  items: T[];
  imageBaseUrl: string;
  onClick: (item: T) => void;
}


function ResponsiveItemList<T extends ImageItem> (props: ResponsiveItemListProps<T>) {
  const xxl = useMediaQuery('(min-width:1800px)');
  const xl = useMediaQuery('(min-width:1536px)');
  const lg = useMediaQuery('(min-width:1200px)');
  const mdlg = useMediaQuery('(min-width:1040px)');
  const md = useMediaQuery('(min-width:900px)');
  const sm = useMediaQuery('(min-width:600px)');
  const xs = useMediaQuery('(max-width:600px)');
  const { imageBaseUrl, items, onClick } = props
  let cols = 2
  if (xxl) {
    cols = 6
  } else if (xl) {
    cols = 5
  } else if (lg) {
    cols = 4
  }  else if (mdlg) {
    cols = 3
  } else if (md) {
    cols = 2
  } else if (sm) {
    cols = 2
  } else if (xs) {
    cols = 1
  }
  return (
    <ImageList sx={{ width: '100%' }} cols={cols}>      
      {items.map((item : T, idx : number) => (
        <ImageListItem key={`${item.imageUrl}_${idx}`} onClick={() => onClick(item)}>
          <div style={{ cursor: 'pointer', width: '100%'}}>
          <img
            style={{ width: '100%', height: 'auto' }}
            src={`${imageBaseUrl}/small/${item.imageUrl}`}
            alt={item.imageAlt}
            loading="lazy"
          />
          </div>
          <ImageListItemBar
            title={item.imageTitle}
            subtitle={item.imageSubtitle}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.imageTitle}`}                
              >
                <InfoIcon />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

export default ResponsiveItemList