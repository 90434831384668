import { PDFDocument, PDFPage, PDFFont, StandardFonts, rgb } from "pdf-lib";
import {
  artsgruppeOptions,
  blomsterfarveOptions,
  fugtighedOptions,
  jordbundsOptions,
  livsvarighedOptions,
  lysforholdOptions,
  months,
  naerigsforholdOptions,
  phOptions,
} from "../data/enums";
import {
  JordbundEnumType,
  Option,
  PlanteFilter,
  PlanteInfo,
  SurhedsgradEnumType,
} from "../data/models";

const fontColor = rgb(0.1, 0.1, 0.1);
const fontSize = 14;
const largeFontSize = 18;
const lineHeightMultiplier = 1.4;
const marginY = 60;
const marginX = 50;

const newPage = (
  pdfDoc: PDFDocument,
  font: PDFFont,
  blank?: boolean
): PDFPage => {
  const page = pdfDoc.addPage();
  const y = page.getSize().height - marginY + fontSize * lineHeightMultiplier;
  if (!blank) {
    page.drawText("Dansk navn", {
      x: marginX,
      y,
      size: fontSize,
      font: font,
      color: fontColor,
    });
    page.drawText("Videnskabeligt navn", {
      x: marginX + 200,
      y,
      size: fontSize,
      font: font,
      color: fontColor,
    });
  }
  return page;
};

function renderFilterOption<E>(
  page: PDFPage,
  normalFont: PDFFont,
  yOffset: number,
  label: string,
  options: Option<E>[],
  value: E
): number {
  let x = marginX;
  let y = page.getSize().height - marginY;
  const option = options.find((o) => o.value === value) || options[0];
  page.drawText(`${label}: ${option.label}`, {
    x,
    y: y - yOffset,
    size: fontSize,
    font: normalFont,
    color: fontColor,
  });
  return (yOffset += fontSize * lineHeightMultiplier);
}

function renderFilterOptions<E>(
  page: PDFPage,
  normalFont: PDFFont,
  yOffset: number,
  label: string,
  options: Option<E>[],
  values: E[]
): number {
  let x = marginX;
  let y = page.getSize().height - marginY;
  const _options = options.filter((o) => values.find((v) => v === o.value));
  if (_options.length === 0) {
    return yOffset;
  }
  page.drawText(`${label}: ${_options.map((o) => o.label).join(", ")}`, {
    x,
    y: y - yOffset,
    size: fontSize,
    font: normalFont,
    color: fontColor,
  });

  return (yOffset += fontSize * lineHeightMultiplier);
}

const renderHeader = (
  navn: string,
  plantefilter: PlanteFilter,
  page: PDFPage,
  normalFont: PDFFont,
  headerFont: PDFFont
): number => {
  let y = page.getSize().height - marginY;
  let yOffset = 0;
  let x = marginX;

  page.drawText(`${navn}`, {
    x,
    y: y - yOffset,
    size: largeFontSize,
    font: headerFont,
    color: fontColor,
  });
  yOffset += 1.5 * largeFontSize * lineHeightMultiplier;

  page.drawText(`Aktive filtre`, {
    x,
    y: y - yOffset,
    size: fontSize,
    font: headerFont,
    color: fontColor,
  });
  yOffset += fontSize * lineHeightMultiplier;


  const jordbundValue: JordbundEnumType = plantefilter.jordbund || "null";
  const jordbundOption =
    jordbundsOptions.find((jo) => jo.value === jordbundValue) ||
    jordbundsOptions[0];
  page.drawText(`Jordbund: ${jordbundOption.label}`, {
    x,
    y: y - yOffset,
    size: fontSize,
    font: normalFont,
    color: fontColor,
  });
  yOffset += fontSize * lineHeightMultiplier;

  const phValue: SurhedsgradEnumType = plantefilter.ph || "null";
  const phOption = phOptions.find((po) => po.value === phValue) || phOptions[0];
  page.drawText(`Surhedsgrad: ${phOption.label}`, {
    x,
    y: y - yOffset,
    size: fontSize,
    font: normalFont,
    color: fontColor,
  });
  yOffset += fontSize * lineHeightMultiplier;

  yOffset = renderFilterOption(
    page,
    normalFont,
    yOffset,
    "Fugtighed",
    fugtighedOptions,
    plantefilter.fugtighed
  );
  yOffset = renderFilterOption(
    page,
    normalFont,
    yOffset,
    "Lysforhold",
    lysforholdOptions,
    plantefilter.lysforhold
  );
  yOffset = renderFilterOption(
    page,
    normalFont,
    yOffset,
    "Næringsforhold",
    naerigsforholdOptions,
    plantefilter.naeringsforhold
  );

  if (plantefilter.livsvarighed) {
    yOffset = renderFilterOptions(
      page,
      normalFont,
      yOffset,
      "Livsvarighed",
      livsvarighedOptions,
      plantefilter.livsvarighed
    );
  }
  if (plantefilter.blomstringstidspunkt) {
    yOffset = renderFilterOption(
      page,
      normalFont,
      yOffset,
      "Blomstringstidspunkt",
      months,
      plantefilter.blomstringstidspunkt
    );
  }
  if (plantefilter.blomsterfarve) {
    yOffset = renderFilterOptions(
      page,
      normalFont,
      yOffset,
      "Blomsterfarve",
      blomsterfarveOptions,
      plantefilter.blomsterfarve
    );
  }
  if (plantefilter.plantehojde && plantefilter.usePlantehojde) {
    const minHojde = plantefilter.plantehojde[0]
    const maxHojde = plantefilter.plantehojde[1]
    page.drawText(`Plantehøjde: ${minHojde} - ${maxHojde}cm`, {
      x,
      y: y - yOffset,
      size: fontSize,
      font: normalFont,
      color: fontColor,
    })
    yOffset += fontSize * lineHeightMultiplier;
  }
  if (plantefilter.artsgrupper) {
    yOffset = renderFilterOptions(
      page,
      normalFont,
      yOffset,
      "Faunagrupper",
      artsgruppeOptions,
      plantefilter.artsgrupper
    );
  }
  

  yOffset += 1.5 * (fontSize * lineHeightMultiplier);
  page.drawText("Dansk navn", {
    x: marginX,
    y: y - yOffset,
    size: fontSize,
    font: headerFont,
    color: fontColor,
  });
  page.drawText("Videnskabeligt navn", {
    x: marginX + 200,
    y: y - yOffset,
    size: fontSize,
    font: headerFont,
    color: fontColor,
  });
  yOffset += 1 * (fontSize * lineHeightMultiplier);

  return yOffset;
};

export const createPdf = async (
  navn: string,
  plantefilter: PlanteFilter,
  planteliste: PlanteInfo[]
): Promise<Uint8Array> => {
  const pdfDoc = await PDFDocument.create();
  const normalFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
  const headerFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
  let page = newPage(pdfDoc, headerFont, true);
  const { width, height } = page.getSize();

  let yOffset = renderHeader(navn, plantefilter, page, normalFont, headerFont);

  const planter = planteliste.concat([]);
  planter.sort((a: PlanteInfo, b: PlanteInfo) =>
    a.danskNavn.localeCompare(b.danskNavn)
  );
  let idxOffset = 0;
  planter.forEach((plante: PlanteInfo, idx: number) => {
    let y =
      -yOffset +
      height -
      marginY -
      lineHeightMultiplier * fontSize * (idx - idxOffset);
    if (y < marginY) {
      idxOffset = idx;
      yOffset = 0;
      y =
        height - marginY - lineHeightMultiplier * fontSize * (idx - idxOffset);
      page = newPage(pdfDoc, headerFont);
    }
    if (idx % 2 === 0) {
      page.drawRectangle({
        x: 40,
        y: y - 6,
        width: width - 80,
        height: fontSize * lineHeightMultiplier,
        color: rgb(0.9, 0.9, 0.9),
        opacity: 0.5,
      });
    }
    page.drawText(plante.danskNavn, {
      x: marginX,
      y,
      size: fontSize,
      font: normalFont,
      color: fontColor,
    });
    page.drawText(plante.videnskabeligtNavn, {
      x: marginX + 200,
      y,
      size: fontSize,
      font: normalFont,
      color: fontColor,
    });
  });

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};
