import React, { ReactElement } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  LinearProgress,
  TableCell,
  IconButton
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
//import { DateTime } from "luxon";

export type FieldCellProps = {
  title: string;
  source: string;
  item?: Record<string, any>;
};

export type FnFieldCellProps = {
  title: string;
  source: string;
  mapfn: (i : any) => string;
  item?: Record<string, any>;
};

export type EditRowButtonProps<E> = FieldCellProps & {
  onClick: (item: E) => void;
};

export const EditRowButton = (props: EditRowButtonProps<any>) => {
  const { title, item, onClick } = props
  if (!item) {
    return <span>{title}</span>;
  }
  return (
    <IconButton color='inherit' sx={{ paddingTop: 0, paddingBottom: 0, paddingRight: 0}} size='small' onClick={() => onClick(item)}>
      <EditIcon fontSize="small" />
    </IconButton>
  )
}

export const TextFieldCell = (props: FieldCellProps) => {
  const { title, source, item } = props;
  if (!item) {
    return <span>{title}</span>;
  }
  return <span>{item[source]}</span>;
};

export const ListFieldCell = (props: FieldCellProps) => {
    const { title, source, item } = props;
    if (!item) {
      return <span>{title}</span>;
    }
    return <span>{item[source].join(', ')}</span>;
  };

  
export const ListFieldFnCell = (props: FnFieldCellProps) => {
  const { title, mapfn, source, item } = props;
  if (!item) {
    return <span>{title}</span>;
  }
  return <span>{item[source].map(mapfn).join(', ')}</span>;
};


export const BooleanFieldCell = (props: FieldCellProps) => {
  const { title, source, item } = props;
  if (!item) {
    return <span>{title}</span>;
  }
  return <span>{item[source] ? '1' : '0'}</span>;
};

/*
export const UnixTimestampCell = (props: FieldCellProps & { unit?: string;}) => {
  const { title, source, item, unit } = props;
  if (!item) {
    return <span>{title}</span>;
  }
  const unitMultiplier = (unit === 'ms') ? 1 : 1000;
  const value = DateTime.fromMillis(item[source] as number * unitMultiplier) // (item[source] as number * unitMultiplier)
  return <span>{value.setLocale('da').toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}</span>;
};


export const UnixTimeOnlyCell = (props: FieldCellProps & { unit?: string;}) => {
  const { title, source, item, unit } = props;
  
  if (!item) {
    return <span>{title}</span>;
  }
  if (!item[source]) {
    return <span></span>
  }
  const unitMultiplier = (unit === 'ms') ? 1 : 1000;
  const value = DateTime.fromMillis(item[source] as number * unitMultiplier) // (item[source] as number * unitMultiplier)
  return <span>{value.setLocale('fr').toLocaleString(DateTime.TIME_WITH_SECONDS)}</span>;
};

export const UnixDateOnlyCell = (props: FieldCellProps & { unit?: string;}) => {
  const { title, source, item, unit } = props;
  if (!item) {
    return <span>{title}</span>;
  }
  const unitMultiplier = (unit === 'ms') ? 1 : 1000;
  if (!item[source]) {
    return <span></span>
  }
  const value = DateTime.fromMillis(item[source] as number * unitMultiplier) // (item[source] as number * unitMultiplier)
  return <span>{value.setLocale('fr').toLocaleString(DateTime.DATE_SHORT)}</span>;
};
*/
const rowStyle = {
  cursor: "pointer",
  backgroundColor: "#f8f8f8",
  "&:hover": {
    backgroundColor: "#eee",
  },
};
const highlightRowStyle = {
  cursor: "pointer",
  backgroundColor: "#e8e8f4",
  "&:hover": {
    backgroundColor: "#eeeee8",
  },
};

type HighlightFunction<T> = {
  (item: T): boolean;
}

type StaticDataListProps<T> = {
  visible: boolean;
  loading: boolean;
  dense?: boolean;
  items: T[];
  highlight?: HighlightFunction<T>;
  children: ReactElement[] | ReactElement;
  onClick?: (item: T) => void;
  offset?: number;
  limit?: number;
};

const createArray = function(size: number) : string[] {
  let res = []
  for (let i = 0;i < size;i++) {
    res.push('');
  }
  return res
}

const StaticDataList = function <T>({
  dense,
  visible,
  loading,
  items,
  children,
  onClick,
  offset,
  limit,
  highlight
}: StaticDataListProps<T>) {
  if (!visible) {
    return null;
  }
  if (!items) {
    return null;
  }
  const _children: ReactElement[] = Array.isArray(children)
    ? children
    : [children];
  const _offset: number = offset && offset > 0 ? offset : 0
  const _limit: number = limit || limit === 0 ? limit : items.length

  const _items: T[] = items.slice(_offset, _offset + _limit)
  const emptyRows : string[] = createArray(_limit - _items.length)
  const _highlight: HighlightFunction<T> = highlight ? highlight : () => false
  return (
    <div style={{ width: '100%', maxWidth: '100%', overflowX: 'auto'}}>
      <Table size={ dense ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            {_children.map((c: ReactElement, idx: number) => (
              <TableCell key={idx}>{c}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (<TableRow />) :
            _items.map((i: T, idx: number) => (
              <TableRow key={idx} sx={_highlight(i) ? highlightRowStyle : rowStyle}>
                {_children.map((c: ReactElement, idx: number) => (
                  <TableCell key={idx} onClick={() => (onClick ? onClick(i) : {})}>
                    {React.createElement(c.type, { ...c.props, item: i })}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {
              emptyRows.map((i : string, idx: number) => (
                <TableRow key={`_${idx}_empty`}>
                  {_children.map((c: ReactElement, idx: number) => (
                  <TableCell key={idx}>
                    <span style={{ display: 'inline-block'}}></span>                    
                  </TableCell>
                ))}
                </TableRow>
              ))
            }
        </TableBody>
      </Table>
      {loading && <LinearProgress variant="indeterminate" />}
    </div>
  );
};

StaticDataList.defaultProps = {
  loading: false,
  visible: true
}

export default StaticDataList;
