import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import Select from 'react-select'
import { Option } from '../../data/models'
import { FilterTitle } from './FilterPanel'

export interface FilterPanelMultiSelectProps<E> {
  title: string;
  values: Option<E>[];
  options: Option<E>[];
  onChange: (value : E[]) => void;
}

function FilterPanelMultiSelect<E>  (props: FilterPanelMultiSelectProps<E>) {
  const { title, values, options, onChange } = props
  const _summary = values.map((o : Option<E>) => o.label).join(', ')
  return (
    <Accordion>
      <AccordionSummary>
        <FilterTitle title={title} />
        <Typography
          sx={{
            paddingLeft: 1,
            paddingTop: '0.2em',
            fontSize: '0.8em',
            color: 'rgba(0, 0, 0, 0.54)',
          }}
        >
          {_summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Select isMulti onChange={(newValue: any) => onChange((newValue as Option<E>[]).map((o : Option<E>) => o.value))} value={values} options={options} />
      </AccordionDetails>
    </Accordion>
  )
}

export default FilterPanelMultiSelect
