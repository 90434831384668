import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import { SelectOptionPageProps } from './pageModel';
import { phOptions } from '../data/enums';
import { content } from '../texts/texts';
import { useDispatch, useSelector } from 'react-redux';
import { MyRootStore } from '../redux/reducers';
import { setSurhedsgrad } from '../redux/filter/filterActions';
import RadioGroupWithDescription from '../components/common/RadioGroupWithDescription';


function SelectPhPage(props: SelectOptionPageProps) : ReactElement {
  const dispatch = useDispatch()
  const { ph } = useSelector(( state: MyRootStore) => state.filter.planteFilter)
  //const [ selectedPh, setSelectedPh ] = useState<string | null>(ph ? ph.value : null)
  return (
    <>
      <Box sx={{ marginBottom: 2}}>
        <Typography variant="body2">
          {content.selectPhPage.body}
        </Typography>
      </Box>
      <Box>
          <RadioGroupWithDescription ariaLabel={'Vælg ph'} options={phOptions} value={ph} onChange={(value) => dispatch(setSurhedsgrad(value))} />        
      </Box>     
    </>
  )
}

export default SelectPhPage