import { PageData, pages, questionPages } from "../../data/enums";
import {
  DESELECT_PLANTE,
  SelectPlanteAction,
  SELECT_PLANTE,
  SetCurrentPageAction,
  SetLoadingAction,
  SetViewModeAction,
  SET_CURRENT_PAGE,
  SET_LOADING,
  SET_VIEWMODE,
} from "./appActions";
import jordbundApi from "../../service/jordbundApi";
import planteApi from "../../service/planteApi";

// * global PLANTEKATALOG_CONFIG */
declare var PLANTEKATALOG_CONFIG: any;
if (PLANTEKATALOG_CONFIG) {
  jordbundApi.setBaseUrl(PLANTEKATALOG_CONFIG.apiUrl);
  planteApi.setBaseUrl(PLANTEKATALOG_CONFIG.apiUrl);
  // set config on service
}

//const option = (label: string, value: string) => ({ label, value })
const initialPage = pages.welcome;

export type ViewModeEnum = "list" | "tile";

export interface AppStore {
  version: string;
  apiVersion: string;
  currentPage: PageData;
  questionPages: PageData[];
  loading: boolean;
  imageBaseUrl: string;
  selectedPlante: {
    id: string;
    videnskabeligtNavn: string;
  } | null;
  viewMode: ViewModeEnum;
}

const initialState: AppStore = {
  version: PLANTEKATALOG_CONFIG.version,
  imageBaseUrl: PLANTEKATALOG_CONFIG.imageUrl,
  apiVersion: "",
  currentPage: initialPage,
  questionPages: questionPages,
  loading: false,
  selectedPlante: null,
  viewMode: "tile",
};

const app = (state = initialState, action: any): AppStore => {
  switch (action.type) {
    case SET_VIEWMODE:
      const setViewModeAction = action as SetViewModeAction;
      return { ...state, viewMode: setViewModeAction.payload.viewMode };
    case SET_LOADING:
      const loadingAction: SetLoadingAction = action;
      return { ...state, loading: loadingAction.payload };
    case SET_CURRENT_PAGE:
      const currentPageAction: SetCurrentPageAction = action;
      return { ...state, currentPage: currentPageAction.payload.page };
    case SELECT_PLANTE:
      const selectPlanteAction: SelectPlanteAction = action;
      return {
        ...state,
        selectedPlante: {
          id: selectPlanteAction.payload.id,
          videnskabeligtNavn: selectPlanteAction.payload.videnskabeligtNavn,
        },
      };
    case DESELECT_PLANTE:
      return { ...state, selectedPlante: null };
    default:
      return state;
  }
};

export default app;
