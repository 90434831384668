import {
    Box
} from '@mui/material'

const AULogo = ({ height } : { height : number }) =>   (
    <Box
    sx={{
     backgroundColor: '#281c41',
     marginTop: 2,
     marginBottom: 1,
     paddingLeft: 1,
     paddingTop: 1,              
   }}
     >
       <img height={height} alt="AU Logo"
     src="images/au_logo_invert.png"/>
     </Box>
  
)

export default AULogo