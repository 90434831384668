import {
  FormControl,
  RadioGroup,
  Radio,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import { Option } from "../../data/models";

export interface RadioGroupWithDescriptionProps<E> {
  value: E | null;
  options: Option<E>[];
  onChange: (value : E) => void;
  ariaLabel: string;
}

function RadioGroupWithDescription<E>(
  props: RadioGroupWithDescriptionProps<E>
) {
  const { options, value, onChange, ariaLabel } = props;
  return (
    <FormControl sx={{ maxWidth: 1080 }}>
      <RadioGroup value={value} onChange={(evt: any) => onChange(evt.target.value)} aria-label={ariaLabel}>
        {options.map((o: Option<E>) => {
          return (
            <div key={`${o.value}`}>
              <Radio value={o.value} checked={value === o.value} />
                <FormLabel color='primary'>{o.label}</FormLabel>
                <FormHelperText>
                    {o.description}
                </FormHelperText>
            </div>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioGroupWithDescription;
