import { ReactElement } from 'react'

import { Grid } from '@mui/material'
import { PlanteFilterUpdate } from '../data/models'
import useWindowSize from '../react/useWindowSize'
import BackgroundCard from '../components/frontpage/BackgroundCard';
import StartCard from '../components/frontpage/StartCard';
import HowToCard from '../components/frontpage/HowToCard'

export interface WelcomePageProps {
  onNext: (updates: PlanteFilterUpdate) => void
}

function WelcomePageSm(props: WelcomePageProps): ReactElement {
  const windowSize = useWindowSize()

  if (!windowSize || !windowSize.height || !windowSize.width) {
    return <></>
  }
  
  const rootStyle = {
    color: '#f8f8f8',
    minHeight: '125vh',
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/front_tall.jpg'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }

  return (
    <>
      <div style={rootStyle}>
        <div style={{ width: '100%', height: 80 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <StartCard margin={2} logoHeight={50} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <HowToCard margin={2} />
          </Grid>          
          <Grid item xs={12} sm={12} md={6}>
          <BackgroundCard margin={2}/>
          </Grid>          
        </Grid>
      </div>
      
    </>
  )
}

export default WelcomePageSm
