import { strToMonth } from "../data/enums";
import {
  PlanteFilter,
  PlanteInfo,
  Month,
  MonthInterval,
  LivsvarighedEnumType,
} from "../data/models";

export const containsOrNull = (list: string[] | null, value: string) =>
  list === null || contains(list, value);

const contains = (list: string[], value: string) =>
  Boolean(list.find((o: string) => o === value));

const hasOneOf = (
  list: string[] | null,
  values: string[],
  defaultValue: boolean
) => {
  if (list === null) {
    return defaultValue;
  }
  for (let i = 0; i < values.length; i++) {
    if (contains(list, values[i])) {
      return true;
    }
  }
  return false;
};

export const propsHasOneOf = (
  source: Record<string, any>,
  values: string[]
) => {
  for (let i = 0; i < values.length; i++) {
    let prop = values[i];
    if (source[prop] === true) {
      return true;
    }
  }
  return false;
};

const inRange = (month: Month, interval: MonthInterval | null): boolean => {
  if (interval === null) {
    return false;
  }
  return (
    month.numValue >= interval.start.numValue &&
    month.numValue <= interval.end.numValue
  );
};

export const plantefilter = (filter: PlanteFilter) => (p: PlanteInfo) => {
  const jordbund = filter.jordbund;
  const fugtighed = filter.fugtighed;
  const surhedsgrad = filter.ph;
  const naeringsforhold = filter.naeringsforhold;
  const livsvarighed = filter.livsvarighed;
  const lysforhold = filter.lysforhold;

  const blomsterfarver = filter.blomsterfarve;
  const blomstringstidspunkt: Month | null = filter.blomstringstidspunkt
    ? strToMonth(filter.blomstringstidspunkt)
    : null;
  const artsgrupper = filter.artsgrupper;
  const plantehojde = filter.plantehojde;
  let match = true;
  const watchedPlante = p.id === "https://arter.dk/taxa/taxon/details/703b8cf8-f785-ea11-aa77-501ac539d1ea";
  
  if (filter.udbredelse) {
    match = match && hasOneOf(p.udbredelse, filter.udbredelse, true);
    if (watchedPlante)
      console.log({ plante: p, match, udbredelse: filter.udbredelse });
  }
  if (jordbund && jordbund !== 'null') {
    match = match && p.jordbund[jordbund];
    if (watchedPlante) console.log({ plante: p, match, jordbund });
  }
  if (fugtighed && fugtighed !== 'null') {
    match = match && p.fugtighed[fugtighed];
    if (watchedPlante) console.log({ plante: p, match, fugtighed });
  }
  if (surhedsgrad && surhedsgrad !== 'null') {
    match = match && p.ph[surhedsgrad];
    if (watchedPlante) console.log({ plante: p, match, surhedsgrad });
  }
  if (naeringsforhold && naeringsforhold !== "null") {
    match = match && p.naeringsforhold[naeringsforhold];
    if (watchedPlante) console.log({ plante: p, match, naeringsforhold });
  }
  if (blomsterfarver && blomsterfarver.length > 0) {
    match = match && hasOneOf(p.blomsterfarve, blomsterfarver, true);
  }
  if (livsvarighed && livsvarighed.length > 0) {
    match =
      match &&
      Boolean(
        livsvarighed.find((l: LivsvarighedEnumType) => p.livsvarighed[l])
      );
  }
  if (lysforhold && lysforhold !== "null") {
    match = match && p.lysforhold[lysforhold];
  }
  if (artsgrupper && artsgrupper.length > 0) {
    match = match && hasOneOf(p.artsgrupper, artsgrupper, false);
  }
  if (plantehojde && plantehojde.length === 2 && filter.usePlantehojde) {
    const minHojde = plantehojde[0]
    const maxHojde = plantehojde[1]
    match =
      match &&
      p.plantehojdeMax !== null &&
      minHojde <= p.plantehojdeMax &&
      maxHojde >= p.plantehojdeMax;
  }
  if (blomstringstidspunkt) {
    match = match && inRange(blomstringstidspunkt, p.blomstringInterval);
  }
  if (watchedPlante) console.log({ plante: p, match });

  return match;
};
