
export const loremIpsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`
export const loremIpsumShort = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`

export const content = {
  welcomePage: {
    plantekatalogLink: { href: 'https://ecos.au.dk/findplanten', text: 'Læs mere på ecos.au.dk' },
    vaadomraaderText: 'Hvis du vil plante/så på et vådt/vanddækket sted kan du finde information om flere plantearter i',
    vaadomraaderLink: { href: 'https://dce2.au.dk/pub/SR334.pdf', text: 'Planter til minivådområder'},
    collaboration: 'Findplanten.dk er udviklet af Aarhus Universitet med midler fra 15. juni fonden',
    background: {
      intro: 'Ved udvælgelsen af arter, som er medtaget, er lagt særlig vægt på følgende forhold:',
      bullets: [
      'Planten er føde for mange insekter, som lever af de grønne plantedele, pollen og/eller nektar',
      'Plantens frø eller frugter spises af fugle og/eller insekter',
      'Planten udgør et egnet levested for fauna',
      ],
      text: 'De fleste planter er flerårige og næsten alle er hjemmehørende i Danmark. Vi har medtaget enkelte ikke-hjemmehørende arter, der ikke anses for problematiske.'
    },
    howTo: [
      `Med Findplanten.dk kan du finde de rigtige planter til dit biodiversitetsprojekt.`,
      `Når du har zoomet ind på Danmarkskortet og fundet arealet, som du er interesseret i, vil du blive guidet gennem de nødvendige oplysninger i forhold til stedet, som levested for planter.`,
      `Baseret på disse oplysninger vil Findplanten.dk fortælle hvilke plantearter, der er egnede til projektet.`,
      `Ved at klikke på en planteart kan du se hvilken fauna arten understøtter.`
  ]},
  selectAreaPage: {
    header: "Vælg område",
    body: "Her vælger du det område, du er interesseret i at så eller tilplante.",
    disclaimer: `Vigtigt: Du er ansvarlig for at området må beplantes, dvs. at området 
      ikke er omfattet af fredning eller beskyttelse i form af fx Naturbeskyttelsens §3. 
      For at så eller plante skal du være lodsejer eller have aftale med lodsejeren.`
  },
  selectJordbundPage: {
    header: "Jordbundstype",
    body: [
      `Plantekataloget vil angive den jordtype, der som udgangspunkt findes på det valgte område på kortet. 
          Kortet dækker jordtyperne grus, sand, ler, stiv ler og humus.`,
      `Da mange områder er tilført topjord har du her mulighed for selv at vælge jordtype.`,
      `Jordtyperne muld, tørvebund og morbund er ikke dækket af kortlægningen.`  ],
  },
  selectPhPage: {
    header: "Jordbundens surhedsgrad",
    body: "Plantekataloget vil angive jordbundens surhedsgrad for det område du har valgt, men du har mulighed for at ændre dette fx hvis du har viden om at surhedsgraden er en anden end den foreslåede."
  },
  selectFugtighedPage: {
    header: "Jordbundens fugtighed",
    body: ""
  },
  selectOthersPage: {
    header: "Øvrige forhold",
    body: "Her kan du angive hvis der er øvrige forhold der gør sig gældende"
  },
  selectLysforholdPage: {
    header: 'Lysforhold',
    body: ''
  },
  selectNaeringsforholdPage: {
    header: 'Næringsforhold',
    body: ''
  },
}

export const jordtyper = {
  grus: `Grus er en grovsandet jord. Den består af >75 % sand, <20 % finsand, <20 % silt og <5 % ler. Jordtypen dækker JB nummer 1. Vælg kun hvis materialet er meget groft. Ellers vælg sand.`,
  sand: 'Sand er enten finsandet jord eller grov eller fin lerblandet sandjord. Den består af 0-100% finsand, >65 % sand, <25 % silt og <10 % ler. Jordtypen dækker JB numrene 2, 3 og 4.',
  ler: 'Ler er grov eller fin sandblandet lerjord eller ren lerjord, der består af 10-25 % ler, <35 % silt og >90 % sand. Jordtypen dækker JB numrene 5, 6 og 7.',
  stivler: 'Stiv lerjord er svær eller meget svær lerjord. Den består af >25 % ler, <50 % silt og <75 % sand. Jordtypen dækker JB numrene 8 og 9.',
  humus: 'Humus er en jordtype med et højt (>10 %) indhold af organisk materiale. Jordtypen dækker JB nummer 11.',
  muld: 'Muld indeholder en betydelig mængde organisk materiale, som er fuldstændig opblandet i jorden. Muldjord er generelt næringsrig, så sørg for at markere dette under næringsforhold.',
  morbund: 'Morbund har et højt indhold af organisk materiale, som ligger som er mere eller mindre omsat lag på jordoverfladen.',
  torvebund: 'Tørvebund har et højt indhold af organisk materiale, som er delvist omsat og hvor det ofte er muligt at identificere plantearter fx tørvemosser, bladmosser eller star-arter. Tørvejorden er ofte vandmættet.'
}

export const surhedsgrader = {
  staerktSur: 'Stærkt sur jord har en pH-værdi <4',
  sur: 'Sur jord har en pH-værdi mellem4 og 5,99',
  neutral: 'Neutral jord har en pH-værdi mellem 6 og 7,99',
  basisk: 'Basisk jord har en pH-værdi ≥8'
}

export const fugtigheder = {
  'tort': 'Området skal angives som tørt hvis jordtypen er veldrænet fx sand, lerblandet sandjord eller sandblandet lerjord, som yderst sjældent er vandmættet.',
  'fugtigt': 'Området skal angives som fugtigt hvis jordtypen bevirker at jorden har en høj vandkapacitet, hvor der aldrig eller sjældent forekommer stående vand. Det gælder især lerjord men også jorde med højt organisk indhold.',
  'vaadt': 'Området skal angives som vådt hvis det aldrig eller sjældent tørrer ud. Det gælder lerjord i lavninger, stiv lerjord samt jorde med et højt organisk indhold.'
}

export const lysfordholdDescriptions = {
  'skygge': 'Hvis området overvejende ligger i skygge fx fra træer og buske eller bygninger.',
  'solskygge': 'Hvis lysforholdene på området i løbet af dagen varierer mellem fuld sol og skygge.',
  'lysaabent': 'Hvis området overvejende ligger i fuld sol.'
}

export const naeringsfordholdDescriptions = {
  'naeringsrigt': 'Området skal angives som næringsrigt, hvis det tilføres gødning eller hvis jordbunden har et højt næringsindholdet, som det er tilfældet i muldjord eller på lerjord.',
  'naeringsfattigt': 'Området skal angives som næringsfattigt, hvis der ikke tilføres gødning og jordbunden samtidig har et lavt næringsindhold, som det er tilfældet for sand og grus men også for jorde, hvor omsætningen er langsomt som det er tilfældet på mor- og tørvebund.'
}