import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import { SelectOptionPageProps } from './pageModel';
import { fugtighedOptions } from '../data/enums';
import { content } from '../texts/texts';
import { useDispatch, useSelector } from 'react-redux';
import { MyRootStore } from '../redux/reducers';
import { setFugtighed } from '../redux/filter/filterActions';
import RadioGroupWithDescription from '../components/common/RadioGroupWithDescription';


function SelectFugtighedPage(props: SelectOptionPageProps) : ReactElement {
  const dispatch = useDispatch()
  const fugtighed = useSelector(( state : MyRootStore) => state.filter.planteFilter.fugtighed)

  return (
    <>
      <Box sx={{ marginBottom: 2}}>
        <Typography variant="body2">
          {content.selectFugtighedPage.body}
        </Typography>
      </Box>
      <Box>
        <RadioGroupWithDescription ariaLabel='Vælg fugtighed' options={fugtighedOptions} value={fugtighed || null} onChange={(value) => dispatch(setFugtighed(value))} />
      </Box>
    </>
  )
}

export default SelectFugtighedPage