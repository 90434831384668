import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import Select from 'react-select'
import { Option } from '../../data/models'
import { FilterTitle } from './FilterPanel'

export interface FilterPanelMultiSelectProps<E> {
  title: string;
  value: Option<E> | null;
  options: Option<E>[];
  onChange: (value : E | null) => void;
}

function FilterPanelSelect<E>  (props: FilterPanelMultiSelectProps<E>) {
  const { title, value, options, onChange } = props
  const _summary = value ? value.label : ''
  return (
    <Accordion>
      <AccordionSummary>
        <FilterTitle title={title} />
        <Typography
          sx={{
            paddingLeft: 1,
            paddingTop: '0.2em',
            fontSize: '0.8em',
            color: 'rgba(0, 0, 0, 0.54)',
          }}
        >
          {_summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Select isClearable onChange={(newValue: any) => onChange(newValue ? newValue.value : null)} value={value} options={options} />
      </AccordionDetails>
    </Accordion>
  )
}

export default FilterPanelSelect
