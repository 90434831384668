import { Step, StepButton, StepLabel, Stepper } from "@mui/material";
import { PageData } from "../../data/enums";

export interface EcosStepContainerProps {
    onSelect: (pageData: PageData) => void;
    currentPage: PageData;
    pages: PageData[];
}

const getCurrentPageIndex = (currentPage: PageData, pages: PageData[]) => {
    return pages.findIndex((p : PageData) => p.value === currentPage.value)
}

const EcosStepContainer = (props: EcosStepContainerProps) => {
    const { onSelect, currentPage, pages } = props

    const activeStep = getCurrentPageIndex(currentPage, pages)
    return (
  <Stepper
        sx={{ maxWidth: '80%', marginTop: 3, marginBottom: 1, marginLeft: 3 }}
        activeStep={activeStep}        
  >
    {pages.map((p: PageData, i: number) => (
      <Step key={p.value} completed={i < activeStep}>
          { i < activeStep ? 
          <StepButton onClick={() => onSelect(p)}>{p.label}</StepButton>
            :           
          <StepLabel>
            {p.label}
          </StepLabel>      
}
      </Step>
    ))}
  </Stepper>)
};

export default EcosStepContainer