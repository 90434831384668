import React, { ReactElement } from "react";

import { Feature, Map, MapBrowserEvent, View } from "ol";
import { fromLonLat } from "ol/proj";
import "ol/ol.css";
import Geometry from "ol/geom/Geometry";
import RenderFeature from "ol/render/Feature";

const createMap = (mapOptions: any): Map => {
  const { target } = mapOptions;

  const map = new Map({
    target: target,
    layers: [],
    view: new View({
      center: fromLonLat([0, 0]),
      zoom: 3,
    }),
  });

  return map;
};

export type OlFeature = Feature<Geometry> | RenderFeature;

class OpenLayersMap extends React.Component<any, { isMounted: boolean; map: Map | null}> {
   constructor(props: any) {
    super(props);
    this.state = {
      isMounted: false,
      map: null,
    };   
  }

  componentDidMount = () => {
    const map: Map = this.initMap(this.props);
    const onFeatureClicked = this.props.onFeatureClicked;
    if (onFeatureClicked) {
      map.on("click", function (e: MapBrowserEvent<any>) {        
        const features : OlFeature[] = map.getFeaturesAtPixel(e.pixel);
        if (features.length > 0) {
          onFeatureClicked(features)
        }        
      });
    }
    this.setState({ ...this.state, map, isMounted: true });
  };

  componentWillUnmount = () => {
    this.state.map?.dispose();
  }

  componentDidUpdate(prevProps: any, nextProps: any) {
    if (prevProps.refreshSizeToken !== nextProps.refreshSizeToken) {
      this.state.map?.updateSize()
    }
  }

  initMap(props: any) {
    const { id } = props;
    const mapOptions = {
      target: id,
    };
    return createMap(mapOptions);
  }

  renderChildren = (props: any, map: Map) => {
    const _children: ReactElement[] = Array.isArray(this.props.children)
      ? this.props.children
      : [this.props.children];
    const renderedChildren: ReactElement[] = _children.map(
      (c: ReactElement, idx: number) =>
        React.createElement(c.type, { ...c.props, map: map, key: idx })
    );
    return renderedChildren;
  };

  render = () => {
    const { id, left, right, top, height, heightVh, marginBottom } = this.props; 
    
    const _height = height || `${heightVh}vh`
    const width = "calc(100% - " + left + "px - " + right + "px)";

    return (
      <div
        key={id}
        id={id}
        style={{ position: "relative", top, left, width, height: _height, marginBottom: marginBottom || 0 }}
      >
        {this.state.map && this.renderChildren(this.props, this.state.map)}
      </div>
    );
  };
}
export default OpenLayersMap;
