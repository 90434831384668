import { useState } from 'react'
import { Grid, Typography, Box, TextField, Button } from "@mui/material";
import Modal from '@mui/material/Modal';

import { PlanteFilter, PlanteInfo } from "../data/models";
import { createPdf } from "../util/pdfWriter";

const download = (name: string, pdfBytes: Uint8Array) => {
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${name}.pdf`;
  link.click();
  link.remove();
};

export interface PdfDownloadButtonProps {
  plantefilter: PlanteFilter
  planteliste: PlanteInfo[]
  fullWidth?: boolean
}

const PdfDownloadButton = ({ plantefilter, planteliste, fullWidth } : PdfDownloadButtonProps) => {
  const [ open, setOpen ] = useState(false)
  const [ name, setName ] = useState('')
  return (
    <>
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={{ backgroundColor: 'white', position: 'absolute', top: '30%', left: '30%', padding: 4, minHeight: 150}}>
        <Typography variant='body1'>Angiv navn</Typography>
        <TextField value={name} onChange={(evt: any) => setName(evt.target.value)} />
        <div style={{ minHeight: 8 }} />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button fullWidth  color='secondary' onClick={() => {setOpen(false); createPdf(name, plantefilter, planteliste).then((pdfBytes: Uint8Array) => download(name, pdfBytes))}} variant="contained">
              Download
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth color='primary' onClick={() => setOpen(false)} variant="contained">
              Luk
            </Button>
          </Grid>
        </Grid>
      </Box>
      </Modal>
      <Button fullWidth={fullWidth} color='secondary' onClick={() => setOpen(true)} variant="contained">
        Download
      </Button>
    </>
  );
};

export default PdfDownloadButton;
