import produce from 'immer'
import { PlanteFilter, PlanteInfo } from '../data/models'
import transform from '../data/planteDataTransform'
import { plantefilter } from './planteFilter'

export interface FaunaInteraktionerResponse {
  name: string
  description: string
}

export interface PlanteInfoResponse {
  data: PlanteInfo[]
}

class PlanteApi {
  data: PlanteInfo[] | null

  baseUrl : string | null

  constructor() {
    this.data = null
    this.baseUrl = null
  }

  setBaseUrl(url : string) {
    this.baseUrl = url
  }

  async getById(id: string) : Promise<PlanteInfo | null> {
    if (this.data === null) {
      const response = await fetch(`${this.baseUrl}/plantekatalog`)
      const raw = await response.json()
      this.data = transform(raw)
    }
    return new Promise((resolve, reject) => {
      const result = this.data?.find((e: PlanteInfo) => e.id === id)
      resolve( result || null)
    })
  }

  async getListe(): Promise<PlanteInfoResponse> {
    if (this.data === null) {
      console.log('refresh cache')
      try {
      const response = await fetch(`${this.baseUrl}/plantekatalog`)
      const raw = await response.json()
      this.data = transform(raw)
      } catch (error) {
        console.log({error})
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    }
    return new Promise((resolve, reject) => {
      resolve(produce({ data: this.data || [] }, (draft) => draft))
    })
  }

  async getByFilter(filter: PlanteFilter): Promise<PlanteInfoResponse> {
    if (this.data === null) {
      const response = await fetch(`${this.baseUrl}/plantekatalog`)
      const raw = await response.json()
      this.data = transform(raw)
    }
    return new Promise((resolve, reject) => {
      const result = this.data?.filter(plantefilter(filter))
      resolve({ data: result || [] })
    })
  }

  async getFaunaInteraktioner(scientificName: string) : Promise<FaunaInteraktionerResponse> {
    const name = encodeURIComponent(scientificName.toLowerCase())
    const response = await fetch(`${this.baseUrl}/faunainteraktioner/${name}`)
    return response.json()
  }
}

export default new PlanteApi()
