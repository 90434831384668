import { 
    Typography 
} from "@mui/material"
import CardWrapper from "./CardWrapper"
import { content } from "../../texts/texts"

const HowToCard = ({ margin, fixedHeight } : { fixedHeight?: boolean, margin? : number }) => {
    return (
        <CardWrapper
            fixedHeight={fixedHeight}
            widthPct={margin && 100}
            margin={margin}
            color="secondary"
            title="Sådan gør du"        
      >
            {content.welcomePage.howTo.map(t => {
                return (
                    <Typography key={t} sx={{ marginBottom: 1}}>
                        {t}
                    </Typography>
                )
            })}       
      </CardWrapper>
    )
}

export default HowToCard