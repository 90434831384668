import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import { SelectOptionPageProps } from './pageModel';
import { lysforholdOptions } from '../data/enums';
import { content } from '../texts/texts';
import { useDispatch, useSelector } from 'react-redux';
import { MyRootStore } from '../redux/reducers';
import { setLysforhold } from '../redux/filter/filterActions';
import RadioGroupWithDescription from '../components/common/RadioGroupWithDescription';

function SelectLysforholdPage(props: SelectOptionPageProps) : ReactElement {
  const dispatch = useDispatch()
  const {
    lysforhold
  } = useSelector(( state : MyRootStore) => state.filter.planteFilter)
 
  return (
    <>
      <Box sx={{ marginBottom: 2}}>
        <Typography variant="body2">
          {content.selectLysforholdPage.body}
        </Typography>
      </Box>
      <Box>               
       <RadioGroupWithDescription ariaLabel='Vælg lysforhold' options={lysforholdOptions} value={lysforhold} onChange={value => dispatch(setLysforhold(value))} />
      </Box>
    </>
  )
}

export default SelectLysforholdPage