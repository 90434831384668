import { Box } from '@mui/material'
import { juni15Color } from '../../appTheme'

const Juni15Logo = ({ height } : { height : number }) => (
    <Box
             sx={{
              backgroundColor: juni15Color,
              marginTop: 2,
              marginBottom: 1,
              paddingLeft: 1,
              paddingTop: 1,              
            }}
              >
                <img height={height} alt="15. juni fonden logo"
              src="images/15_juni_fonden_logo_RGB_COLOR.png"/>
              </Box>   
)

export default Juni15Logo