import {
    applyMiddleware,
    createStore,
    compose
} from "redux";
import rootSaga from './rootSaga'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'   

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(sagaMiddleware),
    )
);


sagaMiddleware.run(rootSaga)

export default store;
