import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import PdfDownloadButton from "../components/PdfDownloadButton";
import PlanteListeListView from "../components/PlanteListeListView";
import PlanteListeTileView from "../components/PlanteListeTileView";
import SidebarFilter from "../components/SidebarFilter";

import { MyRootStore } from "../redux/reducers";

const PlanteListeResultatPage = () => {
  const { viewMode } = useSelector((state: MyRootStore) => state.app);
  const { planteFilter, planteliste } = useSelector((state: MyRootStore) => state.filter);
  console.log(planteFilter);
  return (    
    <Box sx={{ padding: 2, marginTop: 8 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
          <SidebarFilter filter={planteFilter} />
          <div style={{ minHeight: 8 }} />
          <PdfDownloadButton fullWidth={true} plantefilter={planteFilter} planteliste={planteliste} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
          { viewMode === 'tile' ? 
          <PlanteListeTileView height={800} />
          :
          <PlanteListeListView height={800} />
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlanteListeResultatPage;
