import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PlanteInfo } from '../data/models'
//import { artsgruppeOptions, blomsterfarveOptions, livsvarighedOptions } from '../data/enums'
import { selectPlante } from '../redux/app/appActions'

import { MyRootStore } from '../redux/reducers'
import ResponsiveItemList from './common/ResponsiveItemList'


const PlanteListeTileView = (props: any) => {
  const planteliste = useSelector((state: MyRootStore) => state.filter.planteliste)
  const imageBaseUrl = useSelector((state: MyRootStore) => state.app.imageBaseUrl)
  const dispatch = useDispatch()
  
  if (planteliste.length < 1) {
    return (<div>
      Ingen resultater. Opdater filtre
    </div>)
  }
  
  planteliste.sort((a: any, b: any) => a.danskNavn.localeCompare(b.danskNavn))
    
  return (
    <React.Fragment>
        <ResponsiveItemList
          imageBaseUrl={imageBaseUrl}
          items={planteliste || []}
          onClick={(item : PlanteInfo) => dispatch(selectPlante(item.id, item.videnskabeligtNavn))}
          />
    </React.Fragment>
  )
}

export default PlanteListeTileView
