import { ReactElement } from 'react'
import { Box, Grid, Link, Typography, useTheme } from '@mui/material'

export interface CardWrapperHeaderProps {
  title: string;
  to?: string;
  onClick?: () => void;
  transition: any;
  color: 'primary' | 'secondary'
}

const CardWrapperHeader = ({ title, to, transition, color, onClick } : CardWrapperHeaderProps) => {
  const theme = useTheme()  
  const backgroundColor = color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main
  const hoverColor = color === 'primary' ? theme.palette.primary.dark : theme.palette.secondary.dark
  return (
    <Grid
      sx={{
        transition: transition,
        boxShadow: 0,
        cursor: onClick ? 'pointer' : 'default',
        borderRadius: 1,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        backgroundColor: backgroundColor,
        pl: 2,
        pt: 1,
        pb: 1,
        '&:hover': (to || onClick) && { backgroundColor: hoverColor },
      }}
      onClick={() => {
        if (to) window.location.href = to
        if (onClick) onClick()
      }}
      item
      xs={12}
    >
      <Typography variant="h5" sx={{ color: 'white', width: '100%' }}>
        {title}
      </Typography>
    </Grid>
  )
}


export interface CardWrapperProps {
  title: string;
  margin?: number;
  widthPct?: number;
  fixedHeight?: boolean;
  children: ReactElement[] | ReactElement;
  to?: string;
  onClick?: () => void;
  color: 'primary' | 'secondary'
}


const calcWidth = (widthPct?: number, margin?: number) : string => {
  let _w = widthPct || 30
  if (margin) {
    let m2 = margin * 2 * 8
    return `calc(${_w}% - ${m2}px)`
  } else {
    return `${_w}%`
  }
}

const CardWrapper = ({ fixedHeight, margin, widthPct, title, children, to, color, onClick } : CardWrapperProps) => {
  const theme = useTheme()
  const t = theme.transitions.create('background-color')
  const width = calcWidth(widthPct, margin)
  console.log({margin, width, title})
  return (
    <Box
      id={`cardwrapper`}
      sx={{
        boxShadow: 4,
        marginLeft: margin,
        marginRight: margin,
        transition: t,
        width: width,
        height: fixedHeight ? 570 : null,
        backgroundColor: 'rgba(0,0,0,0.6)',
        borderRadius: 1,
        '&:hover': {},
      }}
    >
      <Grid container>
        {to ? (
          <Link href={to} variant="h5" sx={{ textDecoration: 'none', color: 'white', width: '100%' }}>
            <CardWrapperHeader transition={t} title={title} to={to} color={color} />
          </Link>
        ) : (
          <CardWrapperHeader onClick={onClick ? () => onClick() : undefined} transition={null} title={title} to={to} color={color} />
        )}

        <Grid item xs={12} sx={{ padding: 2 }}>
          {children}
        </Grid>
      </Grid>
    </Box>
  )
}

export default CardWrapper
