import { useDispatch } from "react-redux";
import { nextPage } from "../../redux/app/appActions"

import { Typography } from '@mui/material'

import CardWrapper from "./CardWrapper"
import Juni15Logo from "./Juni15Logo";
import AULogo from "./AULogo";
import { content } from "../../texts/texts";

const StartCard = ({ margin, logoHeight, fixedHeight} : {fixedHeight?: boolean, logoHeight: number, margin? : number }) => {
    const dispatch = useDispatch();

    return (
    <CardWrapper
        fixedHeight={fixedHeight}
        widthPct={margin && 100}
        margin={margin}
        color="primary"
        title="Start"
        onClick={() => dispatch(nextPage())}
  >
    <Typography
     variant="body1">
        {content.welcomePage.collaboration}
    </Typography>         
    <Juni15Logo height={logoHeight} />
    <AULogo  height={logoHeight} />
  </CardWrapper>)
}

export default StartCard