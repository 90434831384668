import { ArtsgruppeEnumType, LivsvarighedEnumType, LysforholdEnumType, MonthEnumType, PlanteFilter, PlanteInfo, UdbredelseEnumType } from "../../data/models"
import { GeomPoint } from "../../ol/OlFeatureCollection"
import { ReduxAction } from "../actionInterface"

export const INIT_FILTER = '[filter] init'

export const SET_POINT = '[filter] set point'
export const SET_UDBREDELSE = '[filter] set udbredelse'
export const SET_JORDBUND = '[filter] set jordbund'
export const SET_SURHEDSGRAD = '[filter] set surhedsgrad'
export const SET_FUGTIGHED = '[filter] set fugtighed'
export const SET_LYSFORHOLD = '[filter] set lysforhold'
export const SET_NAERINGSFORHOLD = '[filter] set naeringsforhold'
export const SET_KALK = '[filter] set kalk'
export const SET_SALT = '[filter] set salt'
export const SET_KYSTNAERT = '[filter] set kystnaert'
export const SET_LIVSVARIGHED = '[filter] set livsvarighed'
export const SET_BLOMSTRINGSTIDSPUNKT = '[filter] set blomstringstidspunkt'
export const SET_BLOMSTERFARVE = '[filter] set blomsterfarve'
export const SET_PLANTEHOJDE = '[filter] set plantehojde'
export const SET_USEPLANTEHOJDE = '[filter] set useplantehojde'
export const SET_ARTER = '[filter] set arter'

export const FETCH_PLANTELISTE = "[app] fetch planteliste"
export const SET_PLANTELISTE = "[app] set planteliste"
export const SET_FILTER = "[app] set filter"


export interface SetFilterAction extends ReduxAction {
    payload: { filter: PlanteFilter }
}

export interface SetPlanteListeAction extends ReduxAction {
    payload: {
        planteliste: PlanteInfo[]
    }
}

export interface SetPointAction extends ReduxAction {
    payload: { point: GeomPoint }
}

export interface SetFilterStringAction extends ReduxAction {
    payload: string;
}

export interface SetNullableFilterAction<E> extends ReduxAction {
    payload: E | null;
}
export interface SetTypedFilterAction<E> extends ReduxAction {
    payload: E;
}

export interface SetLivsvarighedAction extends ReduxAction {
    payload: LivsvarighedEnumType[];
}

export interface SetFilterBoolAction extends ReduxAction {
    payload: {
        value: boolean | null
    };
}

export const initFilter = () : ReduxAction => ({
    type: INIT_FILTER
})

export const setPoint = (point: GeomPoint) : SetPointAction => ({
    type: SET_POINT,
    payload: {point}
})

export const setUdbredelse = (udbredelse: UdbredelseEnumType[]) : SetTypedFilterAction<UdbredelseEnumType[]> => ({
    type: SET_UDBREDELSE,
    payload: udbredelse
})

export const setJordbund = (jordbund: string) : SetFilterStringAction => ({
    type: SET_JORDBUND,
    payload: jordbund
})

export const setSurhedsgrad = (surhedsgrad: string) : SetFilterStringAction => ({
    type: SET_SURHEDSGRAD,
    payload: surhedsgrad
})

export const setFugtighed = (fugtighed: string) : SetFilterStringAction => ({
    type: SET_FUGTIGHED,
    payload: fugtighed
})


export const setLysforhold = (lysforhold: LysforholdEnumType) : SetFilterStringAction => ({
    type: SET_LYSFORHOLD,
    payload: lysforhold
})

export const setNaeringsforhold = (naeringsforhold: string) : SetFilterStringAction => ({
    type: SET_NAERINGSFORHOLD,
    payload: naeringsforhold
})

/*
export const setKalk = (kalk: boolean | null) : SetFilterBoolAction => ({
    type: SET_KALK,
    payload: { value: kalk }
})

export const setSalt = (salt: boolean | null) : SetFilterBoolAction => ({
    type: SET_SALT,
    payload: { value: salt }
})

export const setKystnaert = (kystnaert: boolean | null) : SetFilterBoolAction => ({
    type: SET_KYSTNAERT,
    payload: { value: kystnaert }
})
*/

export const setLivsvarighed = (livsvarighed: LivsvarighedEnumType[]) : SetLivsvarighedAction => ({
    type: SET_LIVSVARIGHED,
    payload: livsvarighed
})

export const setBlomstringstidspunkt = (blomstringstidspunkt: MonthEnumType | null) : SetNullableFilterAction<MonthEnumType> => ({
    type: SET_BLOMSTRINGSTIDSPUNKT,
    payload: blomstringstidspunkt
})

export const setBlomsterfarve = (farve: string[]) : SetTypedFilterAction<string[]> => ({
    type: SET_BLOMSTERFARVE,
    payload: farve
})

export const setPlantehojde = (hojde: number[] ) : SetTypedFilterAction<number[]> => ({
    type: SET_PLANTEHOJDE,
    payload: hojde
})

export const setUsePlantehojde = (use: boolean) : SetTypedFilterAction<boolean> => ({
    type: SET_USEPLANTEHOJDE,
    payload: use
})

export const setArter = (arter: ArtsgruppeEnumType[]) : SetTypedFilterAction<ArtsgruppeEnumType[]> => ({
    type: SET_ARTER,
    payload: arter
})

export const setFilter = (filter : PlanteFilter): SetFilterAction => ({
    type: SET_FILTER,
    payload: { filter }
}) 

export const setPlanteListe = (planteliste: PlanteInfo[]) : SetPlanteListeAction => ({
    type: SET_PLANTELISTE,
    payload: { planteliste }
})
