import { all } from 'redux-saga/effects'
import appWatcher from './app/appSagas'
import filterWatcher from './filter/filterSagas'


export default function* rootSaga() {
    yield all([
        appWatcher(),
        filterWatcher()
    ])
}
