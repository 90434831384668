import { ReactElement } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import { SelectOptionPageProps } from "./pageModel";
import { jordbundsOptions } from "../data/enums";
import { content } from "../texts/texts";
import { useDispatch, useSelector } from "react-redux";
import { MyRootStore } from "../redux/reducers";
import { setJordbund } from "../redux/filter/filterActions";
import RadioGroupWithDescription from "../components/common/RadioGroupWithDescription";

function SelectJordbundPage(props: SelectOptionPageProps): ReactElement {
  const dispatch = useDispatch();
  const { jordbund } = useSelector(
    (state: MyRootStore) => state.filter.planteFilter
  );
  return (
    <>
      <Box sx={{ marginY: 2 }}>
        <Typography variant="body1">
          {content.selectJordbundPage.body}
        </Typography>
      </Box>
      <RadioGroupWithDescription ariaLabel='Vælg jordbund' options={jordbundsOptions} value={jordbund || null} onChange={(value => dispatch(setJordbund(value)))} />  
    </>
  );
}

export default SelectJordbundPage;

/*
   <Grid container>
        <Grid item xs={12} sm={4}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label={"Vælg jordbund"}
              name={"Vælg jordbund"}
              value={jordbund}
              onChange={(evt: any) => dispatch(setJordbund(evt.target.value))}
            >
              {jordbundsOptions.map((o: IdOption) => (
                <FormControlLabel
                  key={o.value}
                  value={o.value}
                  control={<Radio color="primary" />}
                  label={o.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8}>
          {jordbundsOptions.map((o: IdOption) => {
            console.log({ o });
            return (
              <>
                <Typography variant="h6">{o.label}</Typography>
                <Typography variant="body2">{o.description}</Typography>
              </>
            );
          })}
        </Grid>
      </Grid>*/