import { fugtigheder, jordtyper, lysfordholdDescriptions, naeringsfordholdDescriptions, surhedsgrader } from "../texts/texts"
import { ArtsgruppeEnumType, FughtighedEnumType, IdOption, LivsvarighedEnumType, LysforholdEnumType, Month, MonthEnumType, NaeringsforholdEnumType, NumOption, Option, PlanteFilter } from "./models"

import LightModeIcon from '@mui/icons-material/LightMode'
//import Brightness6Icon from '@mui/icons-material/Brightness6'
import WbTwilightIcon from '@mui/icons-material/WbTwilight'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import BlockIcon from '@mui/icons-material/Block'

export const enumToString = (value: string, options: Option<string>[]) => {
  const option = options.find(o => o.value === value)
  if (!option) {
    return value
  }
  return option.label
}

export const strToMonth = (value: MonthEnumType) : Month | null => {
  return months.find(o => o.value === value) || null
}

export const monthToOption = (m : Month) : Option<string> => ({
  label: m.label, value: m.value
})

export const months : NumOption<MonthEnumType>[] = [  
  { label: 'Januar', value: 'jan', numValue: 1 },
  { label: 'Februar', value: 'feb', numValue: 2 },
  { label: 'Marts', value: 'marts', numValue: 3 },
  { label: 'April', value: 'april', numValue: 4 },
  { label: 'Maj', value: 'maj', numValue: 5 },
  { label: 'Juni', value: 'juni', numValue: 6 },
  { label: 'Juli', value: 'juli', numValue: 7 },
  { label: 'August', value: 'aug', numValue: 8 },
  { label: 'September', value: 'sep', numValue: 9 },
  { label: 'Oktober', value: 'okt', numValue: 10 },
  { label: 'November', value: 'nov', numValue: 11 },
  { label: 'December', value: 'dec', numValue: 12 }
]

export const jordbundsOptions : IdOption[] = [
  { id: 0,  value: 'null', label: 'Ikke angivet', description: '' },
  { id: 1, value: 'grus', label: 'Grus', description: jordtyper.grus },
  { id: 2, value: 'sand', label: 'Sand', description: jordtyper.sand },
  { id: 3, value: 'ler', label: 'Ler', description: jordtyper.ler },
  { id: 4, value: 'stivler', label: 'Stiv ler', description: jordtyper.stivler },
  { id: 1000, value: 'muld', label: 'Muld', description: jordtyper.muld },
  { id: 5, value: 'humus', label: 'Humus', description: jordtyper.humus },
  { id: 1002, value: 'torvebund', label: 'Tørvebund', description: jordtyper.torvebund},
  { id: 1003, value: 'morbund', label: 'Morbund', description: jordtyper.morbund }

]

export const phOptions : IdOption[] = [
  { id: 0,  value: 'null', label: 'Ikke angivet', description: '' },
  { id: 1, value: 'staerksur', label: 'Stærkt sur', description: surhedsgrader.staerktSur },
  { id: 2, value: 'sur', label: 'Sur', description: surhedsgrader.sur },
  { id: 3, value: 'neutral', label: 'Neutral', description: surhedsgrader.neutral },
  { id: 4, value: 'basisk', label: 'Basisk', description: surhedsgrader.basisk }
]

export const fugtighedOptions : Option<FughtighedEnumType>[] = [
  { value: 'null', label: 'Ikke angivet', description: '' },
  { label: 'Tør', value: 'toer', description: fugtigheder.tort },
  { label: 'Fugtig', value: 'fugtig', description: fugtigheder.fugtigt },
  { label: 'Våd', value: 'vaad', description: fugtigheder.vaadt }
]

export const naerigsforholdOptions : Option<NaeringsforholdEnumType>[] = [  
  { label: 'Ikke angivet', value: 'null', description: '' },
  { label: 'Næringsrigt', value: 'rigt', description: naeringsfordholdDescriptions.naeringsrigt },
  { label: 'Næringsfattig', value: 'fattigt', description: naeringsfordholdDescriptions.naeringsfattigt }
]

export const lysforholdOptions : Option<LysforholdEnumType>[] = [
  { value: 'null', label: 'Ikke angivet', description: '', icon: BlockIcon },
  { value: 'skygge', label: 'Skygge', description: lysfordholdDescriptions.skygge, icon: DarkModeIcon },
  { value: 'solskygge', label: 'Sol/skygge', description: lysfordholdDescriptions.solskygge, icon: WbTwilightIcon },
  { value: 'lysaabent', label: 'Lysåbent', description: lysfordholdDescriptions.lysaabent, icon: LightModeIcon }
]

export const blomsterfarveOptions : Option<string>[] = [
  { label: 'Blå', value: 'blå' },
  { label: 'Grøn', value: 'grøn' },
  { label: 'Gul', value: 'gul' },
  { label: 'Hvid', value: 'hvid' },
  { label: 'Lilla', value: 'lilla' },
  { label: 'Rød', value: 'rød' },
  { label: 'Violet', value: 'violet' },
  { label: 'Grå', value: 'grå' }
]

export const livsvarighedOptions : Option<LivsvarighedEnumType>[] = [
    {label: "Enårig", value: "enaarig"},
    {label: "Toårig", value: "toaarig"},
    {label: "Flerårig", value: "fleraarig"}
]

export const artsgruppeOptions : Option<ArtsgruppeEnumType>[] = [
  { label: "Humlebier", value: "humlebier" },
  { label: "Enlige bier", value: "enligeBier" },
  { label: "Dagsommerfugle", value: "dagsommerfugle" },
  { label: "Svirrefluer", value: "svirrefluer" },
  { label: "Fugle", value: "fugle" },
  { label: "Andre arter", value: "andreArter" }  
]

export interface PageData {
  value: 'welcome' | 'selectArea' | 'selectJordbund' | 'selectPh' | 'selectFugtighed' | 'selectLysforhold' | 'selectNaeringsforhold' | 'showResult' ;  
  index: number;
  label: string;
}

export interface Pages extends Record<string, PageData> {
  welcome: PageData;
  selectArea: PageData;
  selectJordbund: PageData;
  selectPh: PageData;
  selectFugtighed: PageData;
  selectLysforhold: PageData;
  selectNaeringsforhold: PageData;
  showResult: PageData;
}

export const pages : Pages = {
  welcome: { value: 'welcome', index: 0, label: '' },
  selectArea: { value: 'selectArea', index: 1, label: 'Område' },
  selectJordbund: { value: 'selectJordbund', index: 2, label: 'Jordbundstype' },
  selectPh: { value: 'selectPh', index: 3, label: 'Surhedsgrad' },
  selectFugtighed: { value: 'selectFugtighed', index: 4, label: 'Fugtighed' },
  selectLysforhold: { value: 'selectLysforhold', index: 5, label: 'Lysforhold' },
  selectNaeringsforhold: { value: 'selectNaeringsforhold', index: 6, label: 'Næringsforhold' },
  showResult: { value: 'showResult', index: 7, label: '' }
}

export const questionPages : PageData[] = [
  pages.selectArea,
  pages.selectJordbund,
  pages.selectPh,
  pages.selectFugtighed,
  pages.selectLysforhold,
  pages.selectNaeringsforhold
]

export const questionPageValidators = {
  selectArea: (plantefilter: PlanteFilter) => {
    return plantefilter.point !== null;
  },
  selectJordbund: (plantefilter: PlanteFilter) => {
    return plantefilter.jordbund !== null;
  },
  selectPh: (plantefilter: PlanteFilter) => {
    return plantefilter.ph !== null;
  },
  selectFugtighed: (plantefilter: PlanteFilter) => {
    return plantefilter.fugtighed !== null;
  },  
  selectLysforhold: (plantefilter: PlanteFilter) => {
    return plantefilter
  },
  selectNaeringsforhold: (plantefilter: PlanteFilter) => {
    return plantefilter
  },
  welcome: (plantefilter: PlanteFilter) => {
    return plantefilter
  },
  showResult: (plantefilter: PlanteFilter) => {
    return plantefilter
  }
}