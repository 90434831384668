import { 
    Link,
    List,
    ListItem, 
    ListItemText,
    Typography 
} from "@mui/material"
import CardWrapper from "./CardWrapper"
import { content } from "../../texts/texts"

const linkStyle = 
    { color: 'white', textDecoration: 'underline' }

const BackgroundCard = ({ margin , fixedHeight } : { fixedHeight?: boolean, margin? : number }) => {
    return (
        <CardWrapper
        fixedHeight={fixedHeight}
        widthPct={margin && 100}
        margin={margin}
        color="secondary"
        title="Baggrund"        
      >
        <Typography>
            {content.welcomePage.background.intro}
        </Typography>
        <List>        
            {content.welcomePage.background.bullets.map((b : string, i: number) => (
                <ListItem key={`${i}`} sx={{ display: 'list-item' }}>                    
                    <ListItemText primary={`- ${b}`} />
                </ListItem>
            ))}
        </List>        
        <Typography>
            {content.welcomePage.background.text}
        </Typography>
        <Link sx={linkStyle} target='_blank' href={content.welcomePage.plantekatalogLink.href}>{content.welcomePage.plantekatalogLink.text}</Link>
        <Typography>
            {content.welcomePage.vaadomraaderText} <Link sx={linkStyle} target='_blank' href={content.welcomePage.vaadomraaderLink.href}>{content.welcomePage.vaadomraaderLink.text}</Link>
        </Typography>
       
      </CardWrapper>
    )
}

export default BackgroundCard