import { apply, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { jordbundsOptions, PageData, pages, phOptions } from "../../data/enums";
import { IdOption } from "../../data/models";
import jordbundApi, { JordbundInfo } from "../../service/jordbundApi";
import planteApi, { PlanteInfoResponse } from "../../service/planteApi";
import { MyRootStore } from "../reducers";

import {
  FetchJordbundsinfoAction,
  FETCH_JORDBUNDSINFO,
  INIT_APP,
  loading,
  RESTART,
  SelectPlanteAction,
  SELECT_PLANTE,
  SHOW_RESULT,
  stopLoading,
  TO_NEXT_PAGE,
} from "./appActions";
import { setCurrentPage } from "./appActions";
import { ToNextPageAction } from "./appActions";
import {
  initFilter,
  setJordbund,
  setPlanteListe,
  setPoint,
  setSurhedsgrad,
  setUdbredelse,
} from "../filter/filterActions";

export interface ApiResponse<T> {
  data: T;
}

function* processInitApp() {
  try {
    console.log("init app");
    const response: PlanteInfoResponse = yield apply(
      planteApi,
      planteApi.getListe,
      []
    );
    yield put(setPlanteListe(response.data));
  } catch (error) {}
}

function* processToNextPage(action: ToNextPageAction) {
  try {
    const currentPage: PageData = yield select(
      (state: MyRootStore) => state.app.currentPage
    );
    let currentIdx = currentPage.index;
    let nextPage: PageData | null = null;
    for (let key in pages) {
      let p = pages[key];
      if (p.index === currentIdx + 1) {
        nextPage = p;
      }
    }
    if (nextPage === null) {
      return;
    }

    yield put(setCurrentPage(nextPage));
  } catch (error) {
    console.log("error", error);
  }
}

function* processFetchJordbundsInfo(action: FetchJordbundsinfoAction) {
  try {
    yield put(loading());
    const response: JordbundInfo = yield apply(
      jordbundApi,
      jordbundApi.getJordbundInfo,
      [action.payload.point]
    );

    const jordbund = jordbundsOptions.find(
      (o: IdOption) => o.id === response.jordbund
    );
    const ph = phOptions.find((o: IdOption) => o.id === response.ph);
    console.log({ response });
    if (jordbund) {
      yield put(setJordbund(jordbund.value));
    }
    if (ph) {
      yield put(setSurhedsgrad(ph.value));
    }
    if (response.udbredelse) {
      yield put(setUdbredelse(response.udbredelse));
    }
    yield put(setPoint(action.payload.point));
    yield put(stopLoading());
    //yield put(nextPage())
  } catch (error) {
    yield put(stopLoading());
    console.log("error", error);
  }
}

function* processRestart() {
  try {
    let nextPage: PageData = pages.welcome;
    yield put(initFilter());
    yield put(setCurrentPage(nextPage));
  } catch (error) {
    console.log("error", error);
  }
}

function* processShowResult() {
  try {
    let nextPage: PageData = pages.showResult;
    yield put(setCurrentPage(nextPage));
  } catch (error) {
    console.log("error", error);
  }
}

function* processSelectPlante(action: SelectPlanteAction) {
  try {
    const id = action.payload.id;
    const videnskabeligtNavn = action.payload.videnskabeligtNavn;
    console.log({ id, videnskabeligtNavn });
    yield select((state: MyRootStore) => state.app);
  } catch (error) {
    console.log("error", error);
  }
}

export default function* watcher() {
  yield takeLatest(INIT_APP, processInitApp);
  yield takeLatest(SHOW_RESULT, processShowResult);
  yield takeLatest(TO_NEXT_PAGE, processToNextPage);
  yield takeEvery(RESTART, processRestart);
  yield takeEvery(FETCH_JORDBUNDSINFO, processFetchJordbundsInfo);
  yield takeEvery(SELECT_PLANTE, processSelectPlante);
}
