import { combineReducers } from 'redux'

import app, { AppStore } from '../app/appReducer'
import filter, { FilterStore } from '../filter/filterReducer';


export interface MyRootStore {
  app: AppStore;
  filter: FilterStore;
}

const appReducer = combineReducers({
  app,
  filter
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action)
}
export default rootReducer
